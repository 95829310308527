import { FirestoreConverter } from '@/common/infra/firebase/firestore/firestore.converter'
import { Company } from '../../models/company'
import { DocumentData } from 'firebase/firestore'

export class CompanyConverter extends FirestoreConverter<Company, string> {
  convertModel(company: Company): DocumentData {
    return {
      name: company.name,
      type: company.type,
      users: company.users,
    }
  }
  convertDocument(id: string, doc: DocumentData): Company {
    return new Company(id, doc.name, doc.type, doc.users)
  }
}
