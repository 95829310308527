import { Model } from '../../common/model'

export class User extends Model<string> {
  constructor(
    id: string,
    public company: string,
    public name: string,
    public email: string,
    public phone: string
  ) {
    super(id)
  }
}
