import { DomainError } from './domain.error'

export class UnexpectedError extends DomainError {
  constructor(
    public readonly cause: Error,
    public readonly shortMessage = 'Error inesperado'
  ) {
    super(`${shortMessage}: ${cause.message}`, { cause })
  }
}
