import React from 'react'
import LogoIcon from './icons/logo-icon'
import { SVGIconSize } from './icons/svg-icon'
import { cn } from '@lib/utils'

export type LogoProps = {
  collapsed?: boolean
  size?: SVGIconSize
  color?: string
}

const Logo = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & LogoProps
>(({ className, collapsed, size, color, ...props }, ref) => {
  size ??= '2xl'
  color ??= 'primary'
  return (
    <div
      ref={ref}
      {...props}
      className={cn(className, 'flex items-stretch uppercase tracking-widest')}
    >
      <LogoIcon size={size} color={color} />
      {!collapsed && (
        <div
          className={`flex flex-col justify-end ml-3 text-2xl leading-6 font-${
            size === '2xl' ? 'normal' : 'light'
          } text-${color}`}
        >
          AUTONOMOUS
        </div>
      )}
    </div>
  )
})

export default Logo
