import { Failure, PossibleResults, Result, Success } from '@lib/logic/result'
import { getAuthService } from '../../auth/infra/firebase/services/auth.service'
import { AuthSignupFailure } from '../../auth/services/auth.service'
import { SignupDto } from '../dto/signup.dto'
import { ensureWillSucceed } from '@lib/logic/utils'
import { DomainError } from '../../common/errors/domain.error'
import { UnexpectedError } from '../../common/errors/unexpected.error'
import { getUserRepository } from '../../user/infra/firebase/repositories/user.repository'
import { User } from '../../user/models/user'
import { Company } from '../../company/models/company'
import { getCompanyRepository } from '../../company/infra/firebase/company.repository'
import { Branch } from '@/company/models/branch'
import { getBranchRepository } from '@/company/infra/firebase/branch.repository'
import { getCertificateStorageService } from '~/modules/company/infra/storage/certificate-storage.service'

export type SignupResult = {
  company: Company
  user: User
  branch: Branch
}
export type SignupSuccess = Success<SignupResult>
export type SignupFailure = Failure<SignupResult, AuthSignupFailure['error']>

export async function signup(
  dto: SignupDto
): Promise<PossibleResults<SignupSuccess, SignupFailure>> {
  const authService = getAuthService()
  const userRepository = getUserRepository()
  const companyRepository = getCompanyRepository()
  const branchRepository = getBranchRepository()
  const certificateStorageService = getCertificateStorageService()

  try {
    const userCredential = await ensureWillSucceed(authService.signup(dto))

    const company = new Company(dto.cnpj, dto.companyName, dto.companyType, [
      userCredential.uid,
    ])
    await companyRepository.add(company)

    const promises: Promise<void>[] = []

    const branch = new Branch(
      company.id,
      dto.companyName,
      dto.digitalCertificatePassword
    )
    promises.push(branchRepository.add(company.id, branch))

    const user = new User(
      userCredential.uid,
      company.id,
      userCredential.name,
      userCredential.email,
      dto.phone
    )
    promises.push(userRepository.add(user))
    // TODO: explicit error handling in case of CNPJ already registered

    if (dto.digitalCertificate) {
      promises.push(
        certificateStorageService.store(
          company.id,
          company.id,
          dto.digitalCertificate
        )
      )
    }

    await Promise.all(promises)

    return Result.succeed({ company, user, branch })
  } catch (e: any) {
    if (e instanceof DomainError) {
      return Result.fail(e as SignupFailure['error'])
    }
    return Result.fail(new UnexpectedError(e as Error))
  }
}
