import { useState } from 'react'
import { Card } from '@components/ui/card'
import { Button } from '@components/ui/button'
import { Countdown } from '@components/ui/countdown'
import { toast } from 'react-toastify'
import { sendVerificationCode } from '@/onboarding/use-cases/send-verification-code'

export function EmailVerificationNotice() {
  const [isOnCooldown, setIsOnCooldown] = useState(true)

  async function resendVerificationCode() {
    const result = await sendVerificationCode()
    if (result.isFailure) {
      toast.error(
        'Houve um erro ao enviar o código de verificação. Contate o nosso suporte se o erro persistir.'
      )
      return
    }
    setIsOnCooldown(true)
  }

  return (
    <Card className="px-8 py-4 bg-secondary shadow-light-sm">
      <h3 className="mb-6 text-base font-regular text-foreground">
        Por favor, verifique seu e-mail para ter acesso as funcionalidades do
        sistema.
      </h3>
      {isOnCooldown ? (
        <p>
          <span className="text-sm font-normal">
            Solicite o reenvio do e-mail em{' '}
            <Countdown
              countFrom={30}
              onComplete={() => setIsOnCooldown(false)}
            />
          </span>
        </p>
      ) : (
        <p>
          <span className="text-sm font-normal">
            Não recebeu o e-mail de verificação?
          </span>
          <Button
            type="button"
            className="text-left p-0 h-fit text-foreground text-sm font-normal"
            variant="link"
            onClick={resendVerificationCode}
          >
            Clique aqui para reenviar o e-mail.
          </Button>
        </p>
      )}
    </Card>
  )
}
