import React, { useEffect, useState } from 'react'

export const Countdown = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement> & {
    countFrom: number
    onComplete: () => void
  }
>(({ countFrom, onComplete, ...props }, ref) => {
  const [timeLeft, setTimeLeft] = useState(+countFrom)

  const formatTime = (time: number) => {
    return Math.floor(time / 60) + (time % 60)
  }

  useEffect(() => {
    if (timeLeft <= 0) {
      onComplete()
      return
    }
    const timeout = setTimeout(() => setTimeLeft(timeLeft - 1), 1000)
    return () => clearTimeout(timeout)
  }, [timeLeft, onComplete])

  return (
    <span ref={ref} {...props}>
      {formatTime(timeLeft)}
    </span>
  )
})
