import Logo from '@components/ui/logo'
import { Outlet } from 'react-router-dom'

export function SignupLayout() {
  return (
    <div className="min-h-screen bg-primary pb-16">
      <header className="flex flex-col w-full items-center pt-20 pb-4 px-9 gap-y-10 mb-10 bg-background max-md:rounded-b-3xl shadow-xl">
        <div className="flex flex-col items-centerr">
          <Logo />
        </div>
        <div className="max-w-sm w-full sm:text-center">
          <span className="uppercase tracking-widest text-base">Cadastro</span>
        </div>
      </header>
      <Outlet />
    </div>
  )
}
