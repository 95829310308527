import React from 'react'
import { cn } from '~/lib/utils'

export const Overlay = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'fixed h-screen w-screen top-0 left-0 bg-[#40000000]',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
})
