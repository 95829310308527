import React from 'react'
import SVGIcon from './svg-icon'

const TrashIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>(({ className, ...props }, ref) => {
  return (
    <SVGIcon ref={ref} {...props} viewBox="0 0 7.78 10">
      <path d="M0.555556 8.88889C0.555556 9.18357 0.672619 9.46619 0.880992 9.67456C1.08937 9.88294 1.37198 10 1.66667 10H6.11111C6.4058 10 6.68841 9.88294 6.89679 9.67456C7.10516 9.46619 7.22222 9.18357 7.22222 8.88889V2.22222H0.555556V8.88889ZM1.66667 3.33333H6.11111V8.88889H1.66667V3.33333ZM5.83333 0.555556L5.27778 0H2.5L1.94444 0.555556H0V1.66667H7.77778V0.555556H5.83333Z" />
    </SVGIcon>
  )
})

TrashIcon.displayName = 'TrashIcon'

export default TrashIcon
