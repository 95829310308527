import { Vehicle } from '@/vehicle/models/vehicle'
import { VehicleConverter } from '../converters/vehicle.converter'
import { singleton } from '~/lib/singleton'
import { SubcollectionRepository } from '@/common/infra/firebase/firestore/subcollection.repository'

export class VehiclesRepository extends SubcollectionRepository<
  Vehicle,
  string
> {
  protected subcollection = 'vehicles'
  protected collection = 'companies'
  protected converter = new VehicleConverter()
}

export const getVehiclesRepository = singleton(() => new VehiclesRepository())
