import React from 'react'
import SVGIcon, { SVGIconSizes } from './svg-icon'

const sizes: SVGIconSizes = {
  xs: [16, 12],
  sm: [24, 18],
  md: [32, 24],
  lg: [42, 32],
  xl: [48, 36],
  '2xl': [56, 42],
  '3xl': [64, 48],
}

const LogoIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>((props, ref) => (
  <SVGIcon ref={ref} {...props} sizes={sizes} viewBox="0 0 56 42">
    <path d="m 28.5,25.5939 7.5661,-0.0162 1.1661,1.9688 -8.7322,0.0162 z m 0,3.9223 9.1208,-9e-4 1.1661,1.9689 -10.2869,9e-4 z m 19.2264,10.4995 c 1.2905,0 2.3321,-0.8794 2.3321,-1.9688 0,-1.0894 -1.0416,-1.9688 -2.3321,-1.9688 -1.2904,0 -2.332,0.8794 -2.332,1.9688 0,1.0894 1.0416,1.9688 2.332,1.9688 z m 2.3321,-11.8129 h -3.8868 v 3.2814 h 6.934 z M 32.1794,40.0157 c 1.2904,0 2.332,-0.8794 2.332,-1.9688 0,-1.0894 -1.0416,-1.9688 -2.332,-1.9688 -1.2904,0 -2.3321,0.8794 -2.3321,1.9688 0,1.0894 1.0417,1.9688 2.3321,1.9688 z M 50.8359,26.234 55.5,31.4842 v 6.5627 h -3.1094 c 0,2.1789 -2.0833,3.9377 -4.6642,3.9377 -2.5808,0 -4.6641,-1.7588 -4.6641,-3.9377 h -6.2188 c 0,2.1789 -2.0989,3.9377 -4.6641,3.9377 C 29.5986,41.9846 28.5,40 28.5,39.5 v -1.2031 -2.8588 h 0.6968 c 0.8551,-0.8007 1.5989,-1.3288 2.9826,-1.3288 1.3837,0 2.6274,0.5119 3.4825,1.3125 h 7.4004 V 23.6088 L 28.5,23.6251 c 0,-1.1089 0,-2.2322 0,-2.6251 l 17.6717,-0.0162 v 5.2502 z" />
    <path d="M 0,0 V 1.4316406 L 0.00195313,42 H 27.445312 V 0 H 1.5957031 Z M 2.8613281,2.8613281 H 24.582031 V 39.138672 H 2.8632813 Z" />
    <path d="M 5.93523,9.23226 H 11.2571 V 4.5654 H 5.93523 Z" />
    <path d="m 16.321,9.23226 h 5.3219 V 4.5654 H 16.321 Z" />
    <path d="m 16.321,18.566 h 5.3219 V 13.8991 H 16.321 Z" />
    <path d="M 5.93523,18.566 H 11.2571 V 13.8991 H 5.93523 Z" />
    <path d="M 5.93523,27.8997 H 11.2571 V 23.2328 H 5.93523 Z" />
    <path d="m 16.321,27.8997 h 5.3219 V 23.2328 H 16.321 Z" />
    <path d="m 16.321,37.2334 h 5.3219 V 32.5666 H 16.321 Z" />
    <path d="M 5.93523,37.2334 H 11.2571 V 32.5666 H 5.93523 Z" />
  </SVGIcon>
))

LogoIcon.displayName = 'LogoIcon'

export default LogoIcon
