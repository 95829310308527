import { Failure, PossibleResults, Result, Success } from '~/lib/logic/result'
import { Branch } from '../models/branch'
import { UnexpectedError } from '@/common/errors/unexpected.error'
import { getBranchRepository } from '../infra/firebase/branch.repository'
import { getCertificateStorageService } from '../infra/storage/certificate-storage.service'

export type AddBranchResult = PossibleResults<
  Success<Branch>,
  Failure<Branch, UnexpectedError>
>

export type AddBranchDto = {
  company: string
  cnpj: string
  name: string
  digitalCertificate?: File
  digitalCertificatePassword?: string
}

export async function addBranch({
  company,
  cnpj,
  name,
  digitalCertificate,
  digitalCertificatePassword,
}: AddBranchDto): Promise<AddBranchResult> {
  try {
    const branch = new Branch(cnpj, name, digitalCertificatePassword)
    const branchRepository = getBranchRepository()
    await branchRepository.add(company, branch)

    if (digitalCertificate) {
      const certificateStorageService = getCertificateStorageService()
      await certificateStorageService.store(
        company,
        branch.id,
        digitalCertificate
      )
    }

    return Result.succeed(branch)
  } catch (e) {
    return Result.fail(new UnexpectedError(e as Error))
  }
}
