import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { Form } from '~/components/ui/form'
import { TextField } from '@components/ui/text-field'
import { Button } from '~/components/ui/button'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useSession } from '@/auth/contexts/session'
import { editDriver } from '../../use-cases/edit-driver'
import { toast } from 'react-toastify'
import { Driver } from '../../models/driver'

const requiredString = z
  .string()
  .refine((str) => !!str.length, 'Campo obrigatório')

const formSchema = z.object({
  name: requiredString,
  rg: z.string(),
})

export function EditDriverForm() {
  const { session } = useSession()
  const navigate = useNavigate()
  const { driver } = useLoaderData() as { driver: Driver }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: driver.name,
      rg: driver.rg,
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const result = await editDriver({
      cpf: driver.cpf,
      company: session.user!.company.cnpj,
      ...values,
    })

    if (result.isFailure) {
      console.error(result.error!.cause.stack)
      toast.error(result.error?.message)
      return
    }

    toast.success('Alterações salvas.')
  }

  return (
    <Form {...form}>
      <form
        className="fixed top-0 -z-10 h-screen w-full flex flex-col bg-background"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="h-full flex flex-row flex-wrap justify-center content-start gap-y-4 gap-x-4 px-8 pb-8 pt-28 border-b-2 rounded-b-3xl overflow-y-auto bg-primary shadow-inner-bottom no-scrollbar">
          <TextField
            className="min-w-52 max-w-80"
            name="name"
            label="Nome do veículo"
            form={form}
          />
          <TextField
            className="min-w-52 max-w-80"
            name="rg"
            label="RG"
            form={form}
          />
        </div>
        <div className="bottom-0 w-full flex justify-center px-8 py-5 gap-x-4 bg-background">
          <Button
            className="w-full rounded-sm shadow-light-sm uppercase tracking-widest"
            variant="outline"
            onClick={() => navigate('/motoristas')}
          >
            Cancelar
          </Button>
          <Button
            className="w-full rounded-sm shadow-light-sm uppercase tracking-widest"
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </form>
    </Form>
  )
}
