import { useLoaderData, useOutletContext } from 'react-router-dom'
import { EditDriverForm } from '../components/edit-driver-form'
import { MainLayoutOutletContextValue } from '~/components/main-layout'
import { Driver } from '../../models/driver'

export function EditDriver() {
  const { driver } = useLoaderData() as { driver: Driver }
  const { setPageTitle, setPageSubTitle, setMode, setBackgroundColor } =
    useOutletContext<MainLayoutOutletContextValue>()

  setPageTitle('Editar Motorista')
  setPageSubTitle(`${driver.name} - ${driver.cpf}`)
  setMode('form')
  setBackgroundColor('primary')

  return <EditDriverForm />
}
