import React from 'react'
import SVGIcon from './svg-icon'

const TruckIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>(({ className, ...props }, ref) => {
  return (
    <SVGIcon ref={ref} {...props} viewBox="0 0 26.67 18.67">
      <path d="M20.4859 17.07C21.4542 17.07 22.2359 16.2883 22.2359 15.32C22.2359 14.3517 21.4542 13.57 20.4859 13.57C19.5175 13.57 18.7359 14.3517 18.7359 15.32C18.7359 16.2883 19.5175 17.07 20.4859 17.07ZM22.2359 6.56999H19.3192V9.48665H24.5225L22.2359 6.56999ZM6.48586 17.07C7.45419 17.07 8.23586 16.2883 8.23586 15.32C8.23586 14.3517 7.45419 13.57 6.48586 13.57C5.51753 13.57 4.73586 14.3517 4.73586 15.32C4.73586 16.2883 5.51753 17.07 6.48586 17.07ZM22.8192 4.81999L26.3192 9.48665V15.32H23.9859C23.9859 17.2567 22.4225 18.82 20.4859 18.82C18.5492 18.82 16.9859 17.2567 16.9859 15.32H9.98586C9.98586 17.2567 8.42253 18.82 6.48586 18.82C4.54919 18.82 2.98586 17.2567 2.98586 15.32H0.652527V2.48665C0.652527 1.19165 1.69086 0.15332 2.98586 0.15332H19.3192V4.81999H22.8192ZM2.98586 2.48665V12.9867H3.87253C4.51419 12.275 5.44753 11.82 6.48586 11.82C7.52419 11.82 8.45753 12.275 9.09919 12.9867H16.9859V2.48665H2.98586Z" />
    </SVGIcon>
  )
})

TruckIcon.displayName = 'TruckIcon'

export default TruckIcon
