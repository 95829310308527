import { Failure, PossibleResults, Result, Success } from '~/lib/logic/result'
import { User } from '../models/user'
import { UnexpectedError } from '@/common/errors/unexpected.error'
import { getUserRepository } from '../infra/firebase/repositories/user.repository'

export type EditUserPossibleResults = PossibleResults<
  Success<User>,
  Failure<User, UnexpectedError>
>

export async function editUser(user: User): Promise<EditUserPossibleResults> {
  try {
    const userRepository = getUserRepository()
    await userRepository.update(user)
    return Result.succeed(user)
  } catch (e) {
    return Result.fail(new UnexpectedError(e as Error))
  }
}
