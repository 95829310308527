import { Model } from '@/common/model'

export enum TransportType {
  OWN = 'own',
  THIRD_PARTY = 'thirdParty',
}

export const TransportTypeLabelMap = {
  [TransportType.OWN]: 'Transporte Próprio',
  [TransportType.THIRD_PARTY]: 'Transporte Terceirizado',
}

export enum VehicleType {
  TRACTION = 'traction',
  TRAILER = 'trailer',
}

export const VehicleTypeLabelMap = {
  [VehicleType.TRACTION]: 'Tração/Cavalo',
  [VehicleType.TRAILER]: 'Reboque/Carreta',
}

export enum Wheelset {
  TRUCK = 'truck',
  DOUBLE_AXLE = 'doubleAxle',
  TRACTOR = 'tractor',
  VAN = 'van',
  UTILITY = 'utility',
  OTHER = 'other',
}

export const WheelsetLabelMap = {
  [Wheelset.TRUCK]: 'Truck',
  [Wheelset.DOUBLE_AXLE]: 'Toco',
  [Wheelset.TRACTOR]: 'Cavalo Mecânico',
  [Wheelset.VAN]: 'VAN',
  [Wheelset.UTILITY]: 'Utilitário',
  [Wheelset.OTHER]: 'Outros',
}

export enum VehicleBody {
  NOT_APPLICABLE = 'notApplicable',
  FLATBED = 'flatbed',
  BOX = 'box',
  GRAIN_HOPPER = 'grainHopper',
  CONTAINER_CARRIER = 'containerCarrier',
  TAUNLINER = 'taunliner',
}

export const VehicleBodyLabelMap = {
  [VehicleBody.NOT_APPLICABLE]: 'Não Aplicável',
  [VehicleBody.FLATBED]: 'Aberto',
  [VehicleBody.BOX]: 'Fechada/Baú',
  [VehicleBody.GRAIN_HOPPER]: 'Graneleira',
  [VehicleBody.CONTAINER_CARRIER]: 'Porta Container',
  [VehicleBody.TAUNLINER]: 'Sider',
}

export class Vehicle extends Model<string> {
  public readonly plate: string

  constructor(
    plate: string,
    public name: string,
    public renavam: string,
    public transportType: TransportType,
    public vehicleType: VehicleType,
    public body: VehicleBody,
    public wheelset?: Wheelset,
    public tare?: number,
    public massCapacity?: number,
    public volumeCapacity?: number,
    public state?: string,
    public brand?: string,
    public model?: string
  ) {
    plate = plate.toUpperCase()
    super(plate)
    this.plate = plate
  }
}
