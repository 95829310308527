import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { Form } from '~/components/ui/form'
import { TextField } from '@components/ui/text-field'
import { Button } from '~/components/ui/button'
import { useLoaderData } from 'react-router-dom'
import { toast } from 'react-toastify'
import { User } from '../../models/user'
import { editUser } from '../../use-cases/edit-user'
import { ChangePasswordSheet } from '../../../auth/views/components/change-password.sheet'

const requiredString = z.string().min(1, 'Campo obrigatório')

const formSchema = z.object({
  name: requiredString,
  email: requiredString.email(),
  phone: requiredString,
})

export function MyAccountForm() {
  const { user } = useLoaderData() as { user: User }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: user.name,
      phone: user.phone,
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    user.name = values.name
    user.phone = values.phone

    const result = await editUser(user)

    if (result.isFailure) {
      console.error(result.error!.cause.stack)
      toast.error(result.error?.message)
      return
    }
  }

  return (
    <Form {...form}>
      <form
        className="fixed top-0 -z-10 h-screen w-full flex flex-col bg-background"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="h-full flex flex-row flex-wrap justify-center content-start gap-y-4 gap-x-4 px-8 pb-8 pt-28 border-b-2 rounded-b-3xl overflow-y-auto bg-primary shadow-inner-bottom no-scrollbar">
          <TextField
            className="min-w-52 max-w-80"
            type="email"
            name="email"
            label="E-mail"
            readOnly
            value={user.email}
          />
          <TextField
            className="min-w-52 max-w-80"
            name="name"
            label="Nome"
            form={form}
          />
          <TextField
            className="min-w-52 max-w-80 mb-6"
            name="phone"
            label="Telefone"
            form={form}
          />
          <ChangePasswordSheet />
        </div>
        <div className="bottom-0 w-full flex justify-center px-8 py-5 gap-x-4 bg-background">
          <Button
            className="w-full min-w-52 max-w-80 rounded-sm shadow-light-sm uppercase tracking-widest"
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </form>
    </Form>
  )
}
