import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTrigger,
} from '~/@components/ui/sheet'
import { Button } from '~/components/ui/button'
import { TextField } from '~/components/ui/text-field'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Form } from '~/components/ui/form'
import { sendPasswordRecoveryEmail } from '../../use-cases/send-password-recovery-email'

const requiredString = z.string().min(1, 'Campo obrigatório')
const formSchema = z.object({
  email: requiredString.email(),
})

export function RecoverPasswordSheet() {
  const [open, setOpen] = useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async ({ email }: z.infer<typeof formSchema>) => {
    const result = await sendPasswordRecoveryEmail(email)
    if (result.isFailure) {
      toast.error(result.error!.message)
    }
    toast.success('Link de recuperação de senha enviado.')
    setOpen(false)
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button className="mt-4 text-center text-foreground" variant="link">
          Esqueceu a senha?
        </Button>
      </SheetTrigger>
      <SheetContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <SheetHeader className="text-primary mb-6">
              Recuperar senha
            </SheetHeader>
            <SheetDescription>
              Informe seu e-mail para receber o link de recuperação de senha.
            </SheetDescription>
            <div className="w-full flex flex-col py-8 gap-y-4">
              <TextField
                form={form}
                type="email"
                name="email"
                label="Email"
                variant="secondary"
              />
            </div>
            <SheetFooter>
              <Button type="submit" className="w-full" variant="default">
                Enviar
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  )
}
