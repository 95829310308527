import React from 'react'
import SVGIcon from './svg-icon'

const UserIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>(({ className, ...props }, ref) => {
  return (
    <SVGIcon ref={ref} {...props} viewBox="0 0 22.67 22.67">
      <path d="M9.33339 0C10.5711 0 11.7581 0.491665 12.6332 1.36683C13.5084 2.242 14.0001 3.42899 14.0001 4.66667C14.0001 5.90434 13.5084 7.09133 12.6332 7.9665C11.7581 8.84167 10.5711 9.33333 9.33339 9.33333C8.09572 9.33333 6.90873 8.84167 6.03356 7.9665C5.15839 7.09133 4.66673 5.90434 4.66673 4.66667C4.66673 3.42899 5.15839 2.242 6.03356 1.36683C6.90873 0.491665 8.09572 0 9.33339 0ZM9.33339 2.33333C8.71456 2.33333 8.12106 2.57917 7.68348 3.01675C7.24589 3.45434 7.00006 4.04783 7.00006 4.66667C7.00006 5.2855 7.24589 5.879 7.68348 6.31658C8.12106 6.75417 8.71456 7 9.33339 7C9.95223 7 10.5457 6.75417 10.9833 6.31658C11.4209 5.879 11.6667 5.2855 11.6667 4.66667C11.6667 4.04783 11.4209 3.45434 10.9833 3.01675C10.5457 2.57917 9.95223 2.33333 9.33339 2.33333ZM9.33339 10.5C12.4484 10.5 18.6667 12.0517 18.6667 15.1667V18.6667H6.10352e-05V15.1667C6.10352e-05 12.0517 6.21839 10.5 9.33339 10.5ZM9.33339 12.7167C5.86839 12.7167 2.21673 14.42 2.21673 15.1667V16.45H16.4501V15.1667C16.4501 14.42 12.7984 12.7167 9.33339 12.7167Z" />
    </SVGIcon>
  )
})

UserIcon.displayName = 'UserIcon'

export default UserIcon
