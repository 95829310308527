import { Failure, PossibleResults, Result, Success } from '~/lib/logic/result'
import { AddDriverDto } from '../dto/add-driver.dto'
import { getDriversRepository } from '../infra/firebase/repositories/driver.repository'
import { UnexpectedError } from '@/common/errors/unexpected.error'
import { Driver } from '../models/driver'

export type AddDriverPossibleResults = PossibleResults<
  Success<Driver>,
  Failure<Driver, UnexpectedError>
>

export async function addDriver(
  dto: AddDriverDto
): Promise<AddDriverPossibleResults> {
  try {
    const driver = new Driver(dto.cpf, dto.name, dto.rg)

    const driversRepository = getDriversRepository()
    await driversRepository.add(dto.company, driver)

    return Result.succeed(driver)
  } catch (e) {
    return Result.fail(new UnexpectedError(e as Error))
  }
}
