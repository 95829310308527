// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { singleton } from '@lib/singleton'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDzmjhyyOUhj7w_tZulapBKbgV2hGwCIVY',
  authDomain: 'autonomous-9ca4b.firebaseapp.com',
  projectId: 'autonomous-9ca4b',
  storageBucket: 'autonomous-9ca4b.appspot.com',
  messagingSenderId: '1046436269821',
  appId: '1:1046436269821:web:f9c4e866cf4b69e690b894',
}

export const firebase = singleton(() => {
  // Initialize Firebase
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0]
  const analytics = getAnalytics(app)
  const auth = getAuth(app)
  const db = getFirestore(app)
  const storage = getStorage(app)

  return {
    app,
    analytics,
    auth,
    db,
    storage,
  }
})
