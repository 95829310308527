import { Model } from '@/common/model'

export class Driver extends Model<string> {
  public readonly cpf: string

  constructor(cpf: string, public name: string, public rg: string) {
    cpf = cpf.toUpperCase()
    super(cpf)
    this.cpf = cpf
  }
}
