import { useLoaderData, useOutletContext } from 'react-router-dom'
import { EditVehicleForm } from '../components/edit-vehicle-form'
import { MainLayoutOutletContextValue } from '~/components/main-layout'
import { Vehicle } from '../../models/vehicle'

export function EditVehicle() {
  const { vehicle } = useLoaderData() as { vehicle: Vehicle }
  const { setPageTitle, setPageSubTitle, setMode, setBackgroundColor } =
    useOutletContext<MainLayoutOutletContextValue>()

  setPageTitle('Editar Veículo')
  setPageSubTitle(vehicle.plate)
  setMode('form')
  setBackgroundColor('primary')

  return <EditVehicleForm />
}
