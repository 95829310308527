import { Failure, PossibleResults, Result, Success } from '~/lib/logic/result'
import { getBranchRepository } from '../infra/firebase/branch.repository'
import { UnexpectedError } from '@/common/errors/unexpected.error'
import { MainBranchDeletionError } from '../errors/main-branch-deletion.error'

export type DeleteBranchResult = PossibleResults<
  Success<void>,
  Failure<void, MainBranchDeletionError | UnexpectedError>
>

export type DeleteBranchDto = {
  company: string
  cnpj: string
}

export async function deleteBranch({
  company,
  cnpj,
}: DeleteBranchDto): Promise<DeleteBranchResult> {
  try {
    if (company === cnpj) {
      return Result.fail(new MainBranchDeletionError())
    }

    const branchRepository = getBranchRepository()
    await branchRepository.delete(company, cnpj)
    return Result.succeed()
  } catch (e) {
    return Result.fail(new UnexpectedError(e as Error))
  }
}
