import { Failure, PossibleResults, Result, Success } from '~/lib/logic/result'
import { AddVehicleDto } from '../dto/add-vehicle.dto'
import {
  TransportType,
  Vehicle,
  VehicleBody,
  VehicleType,
  Wheelset,
} from '../models/vehicle'
import { getVehiclesRepository } from '../infra/firebase/repositories/vehicle.repository'
import { UnexpectedError } from '@/common/errors/unexpected.error'
import { isInEnum } from '~/lib/utils'

export type AddVehiclePossibleResults = PossibleResults<
  Success<Vehicle>,
  Failure<Vehicle, UnexpectedError>
>

export async function addVehicle(
  dto: AddVehicleDto
): Promise<AddVehiclePossibleResults> {
  try {
    if (
      !isInEnum(dto.transportType, TransportType) ||
      !isInEnum(dto.vehicleType, VehicleType) ||
      !isInEnum(dto.body, VehicleBody) ||
      (dto.wheelset && !isInEnum(dto.wheelset, Wheelset))
    ) {
      throw new Error('Dados inválidos')
    }

    const vehicle = new Vehicle(
      dto.plate,
      dto.name,
      dto.renavam,
      dto.transportType as TransportType,
      dto.vehicleType as VehicleType,
      dto.body as VehicleBody,
      dto.wheelset as Wheelset | undefined,
      dto.tare,
      dto.massCapacity,
      dto.volumeCapacity,
      dto.state,
      dto.brand,
      dto.model
    )

    const vehiclesRepository = getVehiclesRepository()
    await vehiclesRepository.add(dto.company, vehicle)

    return Result.succeed(vehicle)
  } catch (e) {
    return Result.fail(new UnexpectedError(e as Error))
  }
}
