import { FirestoreRepository } from '@/common/infra/firebase/firestore/firestore.repository'
import { User } from '../../../models/user'
import { UserConverter } from '../converters/user.converter'
import { IRepository } from '../../../../common/repository'
import { singleton } from '@lib/singleton'
import { where } from 'firebase/firestore'

class UserRepository
  extends FirestoreRepository<User, string>
  implements IRepository<User, string>
{
  protected readonly collection = 'users'
  protected readonly converter = new UserConverter()

  public async getCompanyUsers(company: string): Promise<User[]> {
    return this.query(where('company', '==', company))
  }
}

export const getUserRepository = singleton(() => new UserRepository())
