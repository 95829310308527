import { Outlet } from 'react-router-dom'
import { MainHeader } from './main-header'
import { Sidebar, SidebarContainer } from './sidebar'
import { useState } from 'react'
import { ToastContainer } from 'react-toastify'

export type MainLayoutMode = 'logo' | 'title' | 'form'

export type MainLayoutOutletContextValue = {
  setLayoutOptions: (
    mode: MainLayoutMode,
    title: string,
    subtitle?: string,
    backgroundColor?: string
  ) => void
  setMode: (mode: MainLayoutMode) => void
  setPageTitle: (title: string) => void
  setPageSubTitle: (subtitle: string) => void
  setBackgroundColor: (title: string) => void
}

export function MainLayout() {
  const [pageTitle, setPageTitle] = useState('')
  const [pageSubTitle, setPageSubTitle] = useState('')
  const [backgroundColor, setBackgroundColor] = useState('background')
  const [mode, setMode] = useState<MainLayoutMode>('logo')

  function setLayoutOptions(
    mode: MainLayoutMode,
    title = '',
    subtitle = '',
    backgroundColor = 'background'
  ) {
    setMode(mode)
    setPageTitle(title)
    setPageSubTitle(subtitle)
    setBackgroundColor(backgroundColor)
  }

  return (
    <div className={'bg-' + backgroundColor}>
      <SidebarContainer>
        <MainHeader mode={mode} title={pageTitle} subtitle={pageSubTitle} />
        <Sidebar />
        <Outlet
          context={{
            setPageTitle,
            setPageSubTitle,
            setBackgroundColor,
            setMode,
            setLayoutOptions,
          }}
        />
        <ToastContainer />
      </SidebarContainer>
    </div>
  )
}
