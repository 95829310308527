import { MainLayoutOutletContextValue } from '@components/main-layout'
import { Button } from '@components/ui/button'
import { DataGrid } from '@components/ui/data-grid'
import PencilIcon from '@components/ui/icons/pencil-icon'
import PlusIcon from '@components/ui/icons/plus-icon'
import TrashIcon from '@components/ui/icons/trash-icon'
import { useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { getVehiclesRepository } from '../../infra/firebase/repositories/vehicle.repository'
import { MaybeWithTimestamps } from '@/common/model'
import {
  TransportTypeLabelMap,
  VehicleBodyLabelMap,
  Vehicle as VehicleModel,
  VehicleType,
  VehicleTypeLabelMap,
  WheelsetLabelMap,
} from '../../models/vehicle'
import { useSession } from '@/auth/contexts/session'
import { deleteVehicle } from '../../use-cases/delete-vehicle'
import { toast } from 'react-toastify'

export function Vehicles() {
  const { setPageTitle, setMode, setBackgroundColor } =
    useOutletContext<MainLayoutOutletContextValue>()

  setPageTitle('Veículos')
  setMode('title')
  setBackgroundColor('background')

  const { session } = useSession()

  const [vehicles, setVehicles] = useState<
    MaybeWithTimestamps<VehicleModel, string>[]
  >([])
  const [loading, setLoading] = useState(true)

  const vehiclesRepository = getVehiclesRepository()
  useEffect(() => {
    vehiclesRepository
      .getAll(session.user!.company.id)
      .then(setVehicles)
      .finally(() => setLoading(false))
  }, [session.user, vehiclesRepository])

  async function handleDelete(vehicle: VehicleModel) {
    const result = await deleteVehicle(
      session.user!.company.cnpj,
      vehicle.plate
    )
    if (result.isFailure) {
      toast.error('Erro inesperado ao deletar veículo.')
      console.error(result.error!.cause.stack)
    }
    setVehicles(vehicles.filter(({ plate }) => plate !== vehicle.plate))
    toast.success('Veículo deletado com sucesso')
  }

  return (
    <main className="p-8">
      <div className="w-full flex justify-end pb-8">
        <Link to="/novo-veiculo">
          <Button className="uppercase font-medium tracking-widest text-xs gap-x-2 shadow-light-sm rounded-sm">
            <PlusIcon color="primary-foreground" size="xs" />
            Novo veículo
          </Button>
        </Link>
      </div>
      <DataGrid
        items={vehicles}
        getKey={(vehicle) => vehicle.plate}
        renderHeader={(vehicle) => (
          <div className="w-full grid grid-cols-[2fr_2fr_2fr] content-center">
            <div className="text-[#007B5D] font-semibold text-xs text-center sm:text-left self-center pl-4">
              • Ativo
            </div>
            <div className="relative w-[93px] h-[32px] flex justify-center items-center place-self-center">
              <img
                className="absolute left-0 top-0"
                src="/car-plate.png"
                alt="Placa do veículo"
              />
              <span className="relative mt-[5px] ml-2 font-semibold text-sm tracking-wide">
                {vehicle.plate}
              </span>
            </div>

            <div className="top-3 right-3 flex gap-3 place-content-end self-center">
              <Link
                to={`/editar-veiculo/${vehicle.plate}`}
                onClick={(e) => e.stopPropagation()}
              >
                <Button className="h-6 w-6 p-1 rounded-full shadow-center">
                  <PencilIcon color="primary-foreground" size="xs" />
                </Button>
              </Link>
              <Button
                className="h-6 w-6 p-1 rounded-full shadow-center"
                onClick={(e) => {
                  e.stopPropagation()
                  handleDelete(vehicle)
                }}
                variant="destructive"
              >
                <TrashIcon color="primary-foreground" size="xs" />
              </Button>
            </div>
          </div>
        )}
        renderContent={(vehicle) => (
          <div className="flex flex-col gap-y-2 pt-5">
            <div className="w-full flex justify-between font-normal text-sm">
              <span>Renavam</span>
              <span>{vehicle.renavam}</span>
            </div>
            <div className="w-full flex justify-between font-normal text-sm">
              <span>Tipo de Transporte</span>
              <span>{TransportTypeLabelMap[vehicle.transportType]}</span>
            </div>
            <div className="w-full flex justify-between font-normal text-sm">
              <span>Tipo de Veículo</span>
              <span>{VehicleTypeLabelMap[vehicle.vehicleType]}</span>
            </div>
            {vehicle.vehicleType === VehicleType.TRACTION && (
              <div className="w-full flex justify-between font-normal text-sm">
                <span>Tipo do Rodado</span>
                <span>
                  {vehicle.wheelset && WheelsetLabelMap[vehicle.wheelset]}
                </span>
              </div>
            )}
            <div className="w-full flex justify-between font-normal text-sm">
              <span>Tipo da Carroceria</span>
              <span>{VehicleBodyLabelMap[vehicle.body]}</span>
            </div>
            <div className="w-full flex justify-between font-normal text-sm">
              <span>Marca</span>
              <span>{vehicle.brand || '-'}</span>
            </div>
            <div className="w-full flex justify-between font-normal text-sm">
              <span>Modelo</span>
              <span>{vehicle.model || '-'}</span>
            </div>
          </div>
        )}
      />
    </main>
  )
}
