import { type ClassValue, clsx } from 'clsx'
import React, { EventHandler } from 'react'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function capitalize(words: string) {
  words
    .split(' ')
    .map((word) => `${word[0].toUpperCase()}${word.substring(1).toLowerCase()}`)
    .join(' ')
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function isInEnum(value: string, obj: { [k: string]: string | number }) {
  return Object.values(obj).findIndex((enumVal) => value === enumVal) > -1
}

export function composeEventHandlers(
  ...handlers: ((...event: any[]) => any)[]
) {
  return (...event: any[]) => handlers.forEach((handler) => handler(...event))
}
