import { FirestoreConverter } from '@/common/infra/firebase/firestore/firestore.converter'
import { User } from '../../../models/user'
import { DocumentData } from 'firebase/firestore'

export class UserConverter extends FirestoreConverter<User, string> {
  convertModel(user: User): DocumentData {
    return {
      company: user.company,
      name: user.name,
      email: user.email,
      phone: user.phone,
    }
  }
  convertDocument(id: string, doc: DocumentData): User {
    return new User(id, doc.company, doc.name, doc.email, doc.phone)
  }
}
