import { Failure, PossibleResults, Result, Success } from '~/lib/logic/result'
import { User } from '../models/user'
import { UnexpectedError } from '@/common/errors/unexpected.error'
import { getUserRepository } from '../infra/firebase/repositories/user.repository'
import { getAuthService } from '@/auth/infra/firebase/services/auth.service'
import { AddUserDto } from '../dto/add-user.dto'
import { ensureWillSucceed } from '~/lib/logic/utils'
import { getCompanyRepository } from '@/company/infra/firebase/company.repository'
import { NotFoundError } from '@/common/errors/not-found.error'

export type AddUserPossibleResults = PossibleResults<
  Success<User>,
  Failure<User, NotFoundError | UnexpectedError>
>

export async function addUser(
  dto: AddUserDto
): Promise<AddUserPossibleResults> {
  try {
    const companyRepository = getCompanyRepository()
    const company = await companyRepository.get(dto.company)
    if (!company) {
      return Result.fail(new NotFoundError('empresa'))
    }

    const authService = getAuthService()
    const userCredential = await ensureWillSucceed(authService.signup(dto))

    company.addUser(userCredential.uid)
    await companyRepository.update(company)

    const userRepository = getUserRepository()
    const user = new User(
      userCredential.uid,
      dto.company,
      dto.name,
      dto.email,
      dto.phone
    )
    await userRepository.add(user)

    await authService.sendSignInLink(user.email)

    return Result.succeed(user)
  } catch (e) {
    return Result.fail(new UnexpectedError(e as Error))
  }
}
