import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { Form } from '~/components/ui/form'
import { SelectField } from '~/components/ui/select-field'
import states from '~/data/brazillian-states'
import { TextField } from '@components/ui/text-field'
import { Button } from '~/components/ui/button'
import { useLoaderData, useNavigate } from 'react-router-dom'
import {
  TransportType,
  TransportTypeLabelMap,
  Vehicle,
  VehicleBody,
  VehicleBodyLabelMap,
  VehicleType,
  VehicleTypeLabelMap,
  Wheelset,
  WheelsetLabelMap,
} from '../../models/vehicle'
import { useSession } from '@/auth/contexts/session'
import { editVehicle } from '../../use-cases/edit-vehicle'
import { toast } from 'react-toastify'

const requiredEnum = (values: readonly [string, ...string[]]) =>
  z.enum(values, { required_error: 'Campo obrigatório' })

const formSchema = z.object({
  name: z.string(),
  renavam: z.string(),
  transportType: requiredEnum([TransportType.OWN, TransportType.THIRD_PARTY]),
  vehicleType: requiredEnum([VehicleType.TRACTION, VehicleType.TRAILER]),
  wheelset: requiredEnum([
    Wheelset.TRUCK,
    Wheelset.DOUBLE_AXLE,
    Wheelset.TRACTOR,
    Wheelset.VAN,
    Wheelset.UTILITY,
    Wheelset.OTHER,
  ]),
  body: requiredEnum([
    VehicleBody.NOT_APPLICABLE,
    VehicleBody.FLATBED,
    VehicleBody.BOX,
    VehicleBody.GRAIN_HOPPER,
    VehicleBody.CONTAINER_CARRIER,
    VehicleBody.TAUNLINER,
  ]),
  tare: z.coerce.number(),
  massCapacity: z.coerce.number(),
  volumeCapacity: z.coerce.number(),
  state: z.string(),
  brand: z.string(),
  model: z.string(),
})

export function EditVehicleForm() {
  const { session } = useSession()
  const navigate = useNavigate()
  const { vehicle } = useLoaderData() as { vehicle: Vehicle }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: vehicle.name,
      renavam: vehicle.renavam,
      transportType: vehicle.transportType,
      vehicleType: vehicle.vehicleType,
      wheelset: vehicle.wheelset,
      body: vehicle.body,
      tare: vehicle.tare,
      massCapacity: vehicle.massCapacity,
      volumeCapacity: vehicle.volumeCapacity,
      state: vehicle.state,
      brand: vehicle.brand,
      model: vehicle.model,
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const result = await editVehicle({
      plate: vehicle.plate,
      company: session.user!.company.cnpj,
      ...values,
    })

    if (result.isFailure) {
      console.error(result.error!.cause.stack)
      toast.error(result.error?.message)
      return
    }

    toast.success('Alterações salvas.')
  }

  return (
    <Form {...form}>
      <form
        className="fixed top-0 -z-10 h-screen w-full flex flex-col bg-background"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="h-full flex flex-row flex-wrap justify-center gap-y-4 gap-x-4 px-8 pb-8 pt-28 border-b-2 rounded-b-3xl overflow-y-auto bg-primary shadow-inner-bottom no-scrollbar">
          <TextField
            className="min-w-52 max-w-80"
            name="name"
            label="Nome do veículo"
            form={form}
          />
          <TextField
            className="min-w-52 max-w-80"
            name="renavam"
            label="Renavam"
            form={form}
          />
          <TextField
            className="min-w-52 max-w-80"
            type="number"
            name="tare"
            label="Tara em KG"
            form={form}
          />
          <TextField
            className="min-w-52 max-w-80"
            type="number"
            name="massCapacity"
            label="Capacidade em KG"
            form={form}
          />
          <TextField
            className="min-w-52 max-w-80"
            type="number"
            name="volumeCapacity"
            label="Capacidade em M³"
            form={form}
          />
          <SelectField
            className="min-w-52 max-w-80"
            name="state"
            label="Estado"
            form={form}
            options={states}
          />
          <SelectField
            className="min-w-52 max-w-80"
            name="transportType"
            label="* Tipo de transporte"
            form={form}
            options={TransportTypeLabelMap}
          />
          <SelectField
            className="min-w-52 max-w-80"
            name="vehicleType"
            label="* Tipo de veículo"
            form={form}
            options={VehicleTypeLabelMap}
          />
          <SelectField
            className="min-w-52 max-w-80"
            name="wheelset"
            label="* Tipo do rodado"
            form={form}
            options={WheelsetLabelMap}
          />
          <SelectField
            className="min-w-52 max-w-80"
            name="body"
            label="* Tipo da carroceria"
            form={form}
            options={VehicleBodyLabelMap}
          />
        </div>
        <div className="bottom-0 w-full flex justify-center px-8 py-5 gap-x-4 bg-background">
          <Button
            className="w-full rounded-sm shadow-light-sm uppercase tracking-widest"
            variant="outline"
            onClick={() => navigate('/veiculos')}
          >
            Cancelar
          </Button>
          <Button
            className="w-full rounded-sm shadow-light-sm uppercase tracking-widest"
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </form>
    </Form>
  )
}
