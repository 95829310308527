import React from 'react'
import SVGIcon from './svg-icon'

export const HamburgIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>(({ className, ...props }, ref) => {
  return (
    <SVGIcon>
      <path d="M2.28571 0H13.7143C14.3205 0 14.9019 0.231785 15.3305 0.644365C15.7592 1.05695 16 1.61652 16 2.2C16 2.78348 15.7592 3.34306 15.3305 3.75564C14.9019 4.16822 14.3205 4.4 13.7143 4.4H2.28571C1.67951 4.4 1.09812 4.16822 0.66947 3.75564C0.240816 3.34306 0 2.78348 0 2.2C0 1.61652 0.240816 1.05695 0.66947 0.644365C1.09812 0.231785 1.67951 0 2.28571 0ZM18.2857 17.6H29.7143C30.3205 17.6 30.9019 17.8318 31.3305 18.2444C31.7592 18.6569 32 19.2165 32 19.8C32 20.3835 31.7592 20.9431 31.3305 21.3556C30.9019 21.7682 30.3205 22 29.7143 22H18.2857C17.6795 22 17.0981 21.7682 16.6695 21.3556C16.2408 20.9431 16 20.3835 16 19.8C16 19.2165 16.2408 18.6569 16.6695 18.2444C17.0981 17.8318 17.6795 17.6 18.2857 17.6ZM2.28571 8.8H29.7143C30.3205 8.8 30.9019 9.03179 31.3305 9.44437C31.7592 9.85695 32 10.4165 32 11C32 11.5835 31.7592 12.1431 31.3305 12.5556C30.9019 12.9682 30.3205 13.2 29.7143 13.2H2.28571C1.67951 13.2 1.09812 12.9682 0.66947 12.5556C0.240816 12.1431 0 11.5835 0 11C0 10.4165 0.240816 9.85695 0.66947 9.44437C1.09812 9.03179 1.67951 8.8 2.28571 8.8Z" />
    </SVGIcon>
  )
})
