import { FirestoreConverter } from '@/common/infra/firebase/firestore/firestore.converter'
import { Branch } from '../../models/branch'
import { DocumentData } from 'firebase/firestore'

export class BranchConverter extends FirestoreConverter<Branch, string> {
  convertModel(branch: Branch): DocumentData {
    return {
      name: branch.name,
      digitalCertificatePassword: branch.digitalCertificatePassword,
    }
  }
  convertDocument(id: string, doc: DocumentData): Branch {
    return new Branch(id, doc.name, doc.digitalCertificatePassword)
  }
}
