import { firebase } from 'lib/firebase'
import { FirebaseStorage, ref, uploadBytes } from 'firebase/storage'
import { singleton } from '~/lib/singleton'

class CertificateStorageService {
  private storage: FirebaseStorage

  constructor() {
    const { storage } = firebase()
    this.storage = storage
  }

  public async store(
    company: string,
    branch: string,
    certificate: File
  ): Promise<void> {
    const certificateRef = ref(this.storage, `${company}/${branch}/cert.pfx`)
    await uploadBytes(certificateRef, certificate)
  }
}

export const getCertificateStorageService = singleton(
  () => new CertificateStorageService()
)
