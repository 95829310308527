import { Overlay } from '@components/ui/overlay'
import { TruckLoader } from '@components/ui/truck-loader'

export function SessionLoader() {
  return (
    <Overlay className="flex justify-center items-center">
      <div className="w-full px-4">
        <TruckLoader />
        <div className="w-full text-center mt-8 tracking-widest">
          carregando...
        </div>
      </div>
    </Overlay>
  )
}
