import * as React from 'react'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { useControllableState } from '@radix-ui/react-use-controllable-state'

import { Label } from './label'
import { cn } from '@lib/utils'

const RadioButtonGroupContext = React.createContext<{
  value: string | number | undefined
  onValueChange: (value: string) => void
}>({ value: undefined, onValueChange: () => {} })

const RadioButtonGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  const [value, setValue] = useControllableState({
    prop: props.value,
    defaultProp: props.defaultValue,
    onChange: props.onValueChange,
  })

  return (
    <RadioButtonGroupContext.Provider
      value={{
        value,
        onValueChange: setValue,
      }}
    >
      <RadioGroupPrimitive.Root className={className} {...props} ref={ref} />
    </RadioButtonGroupContext.Provider>
  )
})
RadioButtonGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioButtonGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, children, ...props }, ref) => {
  const context = React.useContext(RadioButtonGroupContext)
  const checked = context.value === props.value
  const stateStyles = checked
    ? 'brightness-75'
    : 'hover:brightness-110 cursor-pointer'
  return (
    <Label className={cn('flex', className, stateStyles)}>
      <RadioGroupPrimitive.Item
        ref={ref}
        {...props}
        onClick={() => {
          context.onValueChange(props.value)
        }}
      />
      {children}
    </Label>
  )
})
RadioButtonGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioButtonGroup, RadioButtonGroupItem }
