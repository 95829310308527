import React from 'react'
import SVGIcon from './svg-icon'

const UsersIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>(({ className, ...props }, ref) => {
  return (
    <SVGIcon ref={ref} {...props} viewBox="0 0 28 21">
      <path d="M15.498 9.47407C16.3484 8.28584 16.8057 6.86127 16.8057 5.40007C16.8057 3.93887 16.3484 2.5143 15.498 1.32607C16.2939 0.782811 17.2364 0.494708 18.2 0.500074C19.4996 0.500074 20.7459 1.01632 21.6648 1.93525C22.5838 2.85418 23.1 4.10051 23.1 5.40007C23.1 6.69963 22.5838 7.94597 21.6648 8.8649C20.7459 9.78382 19.4996 10.3001 18.2 10.3001C17.2364 10.3054 16.2939 10.0173 15.498 9.47407ZM4.9 5.40007C4.9 4.43095 5.18738 3.48358 5.7258 2.67778C6.26422 1.87198 7.02949 1.24393 7.92485 0.873064C8.82021 0.502195 9.80544 0.405158 10.7559 0.594226C11.7064 0.783294 12.5795 1.24997 13.2648 1.93525C13.9501 2.62053 14.4168 3.49362 14.6058 4.44413C14.7949 5.39464 14.6979 6.37986 14.327 7.27522C13.9561 8.17058 13.3281 8.93586 12.5223 9.47427C11.7165 10.0127 10.7691 10.3001 9.8 10.3001C8.50044 10.3001 7.25411 9.78382 6.33518 8.8649C5.41625 7.94597 4.9 6.69963 4.9 5.40007ZM7.7 5.40007C7.7 5.81541 7.82316 6.22143 8.05391 6.56677C8.28467 6.91211 8.61264 7.18128 8.99636 7.34022C9.38009 7.49916 9.80233 7.54075 10.2097 7.45972C10.6171 7.37869 10.9912 7.17869 11.2849 6.885C11.5786 6.59131 11.7786 6.21712 11.8596 5.80976C11.9407 5.4024 11.8991 4.98016 11.7401 4.59644C11.5812 4.21271 11.312 3.88474 10.9667 3.65399C10.6214 3.42324 10.2153 3.30007 9.8 3.30007C9.24304 3.30007 8.7089 3.52132 8.31507 3.91515C7.92125 4.30898 7.7 4.84312 7.7 5.40007ZM19.6 18.7001V21.5001H0V18.7001C0 18.7001 0 13.1001 9.8 13.1001C19.6 13.1001 19.6 18.7001 19.6 18.7001ZM16.8 18.7001C16.604 17.6081 14.938 15.9001 9.8 15.9001C4.662 15.9001 2.898 17.7341 2.8 18.7001M19.53 13.1001C20.3881 13.7675 21.0896 14.6147 21.5854 15.5821C22.0813 16.5495 22.3593 17.6138 22.4 18.7001V21.5001H28V18.7001C28 18.7001 28 13.6181 19.516 13.1001H19.53Z" />
    </SVGIcon>
  )
})

UsersIcon.displayName = 'UsersIcon'

export default UsersIcon
