import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/ui/form'
import { Input } from '@components/ui/input'
import { Checkbox } from '@components/ui/checkbox'
import { Button } from '@components/ui/button'
import { Separator } from '@components/ui/separator'
import { useSession } from '../../contexts/session'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useNavigate } from 'react-router-dom'
import { RecoverPasswordSheet } from './recover-password.sheet'

const formSchema = z.object({
  email: z.string().email({
    message: 'E-mail inválido',
  }),
  password: z.string().min(6, {
    message: 'Senha inválida',
  }),
  keepConnected: z.boolean().default(false),
})

export function LoginForm() {
  const navigate = useNavigate()
  const { login } = useSession()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
      keepConnected: false,
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const result = await login(
      values.email,
      values.password,
      values.keepConnected
    )
    if (result.isFailure) {
      toast.error(result.error!.message, {
        autoClose: 5000,
      })
      return
    }
    navigate('/inicio')
  }

  return (
    <div className="max-w-md w-full">
      <Form {...form}>
        <form
          className="flex flex-col w-full gap-y-4"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel className="text-base text-muted-foreground font-normal">
                  Email
                </FormLabel>
                <FormControl>
                  <Input className="border-primary" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel className="text-base text-muted-foreground font-normal">
                  Senha
                </FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    className="border-primary"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="keepConnected"
            render={({ field }) => (
              <FormItem className="w-full flex justify-end items-center space-y-0 space-x-0 gap-2">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabel className="text-base text-muted-foreground font-normal">
                  Mater-me conectado
                </FormLabel>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="font-medium text-base py-6 mt-2 w-full"
            size="lg"
          >
            Entrar
          </Button>
        </form>
      </Form>
      <div className="flex flex-col w-full">
        <RecoverPasswordSheet />
        <div className="w-full flex justify-stretch items-center gap-4 mt-4">
          <div className="w-full shrink-1">
            <Separator />
          </div>
          <div className="h-7">ou</div>
          <div className="w-full shrink-1">
            <Separator />
          </div>
        </div>
        <Link to="/cadastrar-se">
          <Button
            className="font-medium text-base py-6 mt-8 w-full border border-primary"
            variant="ghost"
            size="lg"
          >
            Cadastrar-se
          </Button>
        </Link>
      </div>
      <ToastContainer />
    </div>
  )
}
