import { cn } from '@lib/utils'
import React, { createContext, useContext, useEffect, useState } from 'react'
import ArrowRightIcon from './ui/icons/arrow-right-icon'
import { Button } from './ui/button'
import Logo from './ui/logo'
import { useSession } from '@/auth/contexts/session'
import ExitIcon from './ui/icons/exit-icon'
import SwitchCompanyIcon from './ui/icons/switch-company-icon'
import { Separator } from './ui/separator'
import { Link, useLocation } from 'react-router-dom'
import SVGIcon from './ui/icons/svg-icon'
import TruckIcon from './ui/icons/truck-icon'
import IdCardIcon from './ui/icons/id-card-icon'
import UserIcon from './ui/icons/user-icon'
import UsersIcon from './ui/icons/users-icon'
import HomeIcon from './ui/icons/home-icon'
import { signout } from '@/auth/use-cases/signout'
import { BranchMenu } from './branch-menu'
import { CompanyType } from '@/company/models/company'

export type SidebarContextValue = {
  isOpen: boolean
  open: () => void
  close: () => void
  toggle: () => void
}

const SidebarContext = createContext<SidebarContextValue>({
  isOpen: false,
  open: () => {},
  close: () => {},
  toggle: () => {},
})

export function useSidebarContext() {
  return useContext(SidebarContext)
}

export function SidebarContainer({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        open,
        close,
        toggle,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export function SidebarLink({
  label,
  Icon,
  to,
  active,
}: {
  label: string
  Icon: typeof SVGIcon
  to: string
  active: boolean
}) {
  const { close } = useSidebarContext()

  return (
    <Link
      to={to}
      onClick={() => close()}
      className={cn(
        'w-full flex items-center gap-x-5 px-8 py-3 rounded-full text-primary-foreground tracking-widest font-semibold uppercase',
        active
          ? 'bg-[#00FF85] text-primary cursor-default'
          : 'bg-foreground hover:brightness-125'
      )}
    >
      <Icon size="md" color={active ? 'primary' : 'primary-foreground'} />
      {label}
    </Link>
  )
}

export function Sidebar() {
  const { session } = useSession()

  const { isOpen, close } = useSidebarContext()
  const [isOpenCss, setIsOpenCss] = useState(isOpen ? 'right-0' : 'left-[100%]')
  const [isBranchMenuOpen, setBranchMenuState] = useState(false)

  useEffect(() => {
    setIsOpenCss(isOpen ? 'right-0' : 'left-[100%]')
    if (isOpen) {
      setBranchMenuState(false)
    }
  }, [isOpen])

  const location = useLocation()
  const [currentRoute, setCurrentRoute] = useState(location.pathname)

  useEffect(() => setCurrentRoute(location.pathname), [location])

  return (
    <>
      <div
        className={cn(
          'w-screen h-screen fixed top-0 left-0 flex justify-end bg-transparent pl-8 transition-all z-50',
          isOpenCss
        )}
        onClick={() => close()}
        onTouchEnd={() => close()}
      >
        <nav
          className="bg-foreground max-w-sm w-full rounded-s-3xl relative shadow-l-md"
          onClick={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()}
        >
          <div className={cn('flex flex-col max-w-sm w-full h-screen')}>
            <Button
              variant="ghost"
              className="bg-transparent absolute top-8 left-8 hover:bg-transparent"
              size="icon"
              onClick={() => {
                close()
              }}
            >
              <ArrowRightIcon color="background" />
            </Button>
            <div className="pt-28 pb-2 px-8">
              <Logo
                className="justify-center mb-12"
                color="primary-foreground"
                size="lg"
              />
              <div className="flex justify-between items-center w-full mb-5">
                <div className="flex flex-col text-primary-foreground leading-none">
                  {session.user?.name}
                  <span className="text-xs text-[#667B71]">
                    {session.user?.email}
                  </span>
                </div>
                <Button
                  className="bg-transparent text-primary-foreground tracking-widest gap-x-2 hover:text-primary-foreground p-3 hover:bg-[rgba(0,0,0,0.3)]"
                  variant="ghost"
                  onClick={() => signout()}
                >
                  SAIR
                  <ExitIcon size="md" color="primary-foreground" />
                </Button>
              </div>
              <div className="flex justify-between items-center w-full mb-5">
                <div className="flex flex-col leading-none text-primary-foreground">
                  {session.currentBranch?.name}
                  <span className="text-xs text-[#667B71]">
                    {session.currentBranch?.cnpj}
                  </span>
                </div>
                <Button
                  className={cn(
                    'gap-x-2 p-3 text-primary-foreground hover:text-primary-foreground tracking-widest',
                    isBranchMenuOpen
                      ? 'hover:bg-transparent bg-[rgba(0,0,0,0.3)]'
                      : 'bg-transparent hover:bg-[rgba(0,0,0,0.3)]'
                  )}
                  variant="ghost"
                  onClick={() => setBranchMenuState(!isBranchMenuOpen)}
                >
                  {isBranchMenuOpen ? (
                    <>
                      VOLTAR
                      <ArrowRightIcon
                        className="rotate-180"
                        size="sm"
                        color="primary-foreground"
                      />
                    </>
                  ) : (
                    <>
                      TROCAR
                      <SwitchCompanyIcon size="md" color="primary-foreground" />
                    </>
                  )}
                </Button>
              </div>
            </div>
            <div className="h-full relative">
              <div className="absolute bottom-0 flex flex-col w-full h-full grow px-8">
                <BranchMenu />
              </div>
              <div
                className={cn(
                  'transition-all absolute bottom-0 flex flex-col h-full justify-between w-full bg-primary px-8 py-8 rounded-s-3xl shadow-t-md',
                  isBranchMenuOpen ? 'left-[101%]' : 'left-0'
                )}
              >
                <div className="flex flex-col gap-y-4">
                  <SidebarLink
                    label="Início"
                    to="/inicio"
                    Icon={HomeIcon}
                    active={currentRoute === '/inicio'}
                  />
                  {session.user!.company.type === CompanyType.TRANSPORTER && (
                    <>
                      <SidebarLink
                        label="Veículos"
                        to="/veiculos"
                        Icon={TruckIcon}
                        active={currentRoute === '/veiculos'}
                      />
                      <SidebarLink
                        label="Motoristas"
                        to="/motoristas"
                        Icon={IdCardIcon}
                        active={currentRoute === '/motoristas'}
                      />
                    </>
                  )}
                </div>
                <div className="flex flex-col gap-y-4">
                  <Separator className="bg-[rgba(0,0,0,0.2)]" />
                  <SidebarLink
                    label="Usuários"
                    to="/usuarios"
                    Icon={UsersIcon}
                    active={currentRoute === '/usuarios'}
                  />
                  <SidebarLink
                    label="Minha Conta"
                    to="/minha-conta"
                    Icon={UserIcon}
                    active={currentRoute === '/minha-conta'}
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
