import React from 'react'
import SVGIcon from './svg-icon'

const SwitchCompanyIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>(({ className, ...props }, ref) => {
  return (
    <SVGIcon ref={ref} {...props} viewBox="0 0 20 22">
      <path d="M14 8H16V10H14V8ZM20 14V4H10V0H0V14H20ZM18 12H10V10H12V8H10V6H18V12ZM6 4V2H8V4H6ZM6 8V6H8V8H6ZM6 12V10H8V12H6ZM2 4V2H4V4H2ZM2 8V6H4V8H2ZM2 12V10H4V12H2ZM7 16V18H13V16L16 19L13 22V20H7V22L4 19L7 16Z" />
    </SVGIcon>
  )
})

SwitchCompanyIcon.displayName = 'SwitchCompanyIcon'

export default SwitchCompanyIcon
