import { InvalidCredentialsError } from '../errors/invalid-credentials.error'
import { signin } from '../use-cases/signin'
import { UnexpectedError } from '../../common/errors/unexpected.error'
import { Company, CompanyType } from '../../company/models/company'
import { SignupDto } from '../../onboarding/dto/signup.dto'
import { signup as signupUseCase } from '../../onboarding/use-cases/signup'
import { Failure, PossibleResults, Result, Success } from '@lib/logic/result'
import React, { createContext, useContext, useState } from 'react'
import { EmailAlreadyRegisteredError } from '../errors/email-already-registered.error'
import { InvalidPasswordError } from '../errors/invalid-password.error'
import { createAuthStateListener } from '../infra/firebase/listeners/auth-state.listener'
import { createEvent, EventListener, ListenerDestroyer } from '@lib/event'
import { CodeVerificationResult } from '../services/auth.service'
import { applyVerificationCode } from '../use-cases/apply-verification-code'
import { SessionLoader } from '../views/components/session-loader'
import { getUserRepository } from '@/user/infra/firebase/repositories/user.repository'
import { getCompanyRepository } from '@/company/infra/firebase/company.repository'
import { Branch } from '@/company/models/branch'
import { getBranchRepository } from '@/company/infra/firebase/branch.repository'
import {
  addBranch as addBranchUseCase,
  AddBranchDto,
} from '@/company/use-cases/add-branch'
import { deleteBranch as deleteBranchUseCase } from '@/company/use-cases/delete-branch'
import { UnauthenticatedUserError } from '../errors/unauthenticated-user.error'
import { UserCredential } from '../models/user-credential'

export type LoginSuccess = Success<void>

export type LoginFailure =
  | Failure<void, InvalidCredentialsError>
  | Failure<void, UnexpectedError>

export type LoginResult = PossibleResults<LoginSuccess, LoginFailure>

export type SignupSuccess = Success<void>

export type SignupFailure =
  | Failure<void, EmailAlreadyRegisteredError>
  | Failure<void, InvalidPasswordError>
  | Failure<void, UnexpectedError>

export type SignupResult = PossibleResults<LoginSuccess, LoginFailure>

export type Session =
  | {
      user?: never
      currentBranch?: never
      branches?: never
      loading: boolean
    }
  | {
      user: {
        id: string
        name: string
        email: string
        emailVerified: boolean
        company: Company
      }
      branches: Branch[]
      currentBranch: Branch
      loading: boolean
    }

export type SessionContextValue = {
  session: Session
  login: (
    email: string,
    password: string,
    persistSession: boolean
  ) => Promise<LoginResult>
  signup: (dto: SignupDto) => Promise<SignupResult>
  applyEmailVerificationCode: (
    oobCode: string
  ) => Promise<CodeVerificationResult>
  addBranch: (
    dto: Omit<AddBranchDto, 'company'>
  ) => Promise<
    Success<void> | Failure<void, UnauthenticatedUserError | UnexpectedError>
  >
  switchBranch: (branch: Branch) => void
  deleteBranch: (
    branchId: string
  ) => Promise<
    Success<void> | Failure<void, UnauthenticatedUserError | UnexpectedError>
  >
  useSessionListener: (listener: EventListener<Session>) => void
}

const SessionContext = createContext<SessionContextValue>({
  session: { loading: true },
  login: async (email: string, password: string, persistSession: boolean) =>
    Result.succeed(),
  signup: async (dto: SignupDto) => Result.succeed(),
  applyEmailVerificationCode: async (oobCode) => Result.succeed(false),
  useSessionListener() {},
  addBranch: async (dto: Omit<AddBranchDto, 'company'>) => Result.succeed(),
  switchBranch() {},
  deleteBranch: async () => Result.succeed(),
})

export function useSession() {
  return useContext(SessionContext)
}

export function SessionProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<Session>({ loading: true })

  async function login(
    email: string,
    password: string,
    persistSession: boolean
  ) {
    const result = await signin(email, password, persistSession)
    if (result.isFailure) {
      setSession({ loading: false })
      return Result.fail(result.error!) as LoginFailure
    }
    return Result.succeed()
  }

  async function signup(dto: SignupDto) {
    authStateListenerDestroyer()

    const result = await signupUseCase({
      ...dto,
      companyType: dto.companyType as CompanyType,
    })

    authStateListenerDestroyer = createAuthStateListener(onAuthStateChanged)

    if (result.isFailure) {
      return Result.fail<void>(result.error!)
    }

    const { user, company, branch } = result.value

    setSession({
      user: {
        id: user.id,
        name: user.name,
        email: user.email,
        emailVerified: false,
        company,
      },
      branches: [branch],
      currentBranch: branch,
      loading: false,
    })

    return Result.succeed()
  }

  async function applyEmailVerificationCode(
    oobCode: string
  ): Promise<CodeVerificationResult> {
    return applyVerificationCode(oobCode)
  }

  async function addBranch(
    dto: Omit<AddBranchDto, 'company'>
  ): Promise<
    Success<void> | Failure<void, UnauthenticatedUserError | UnexpectedError>
  > {
    if (!session.user) return Result.fail(new UnauthenticatedUserError())
    const result = await addBranchUseCase({
      company: session.user!.company.id,
      ...dto,
    })

    if (result.isFailure) return Result.fail(result.error!)

    setSession({
      ...session,
      branches: [...session.branches, result.value],
      currentBranch: result.value,
    })

    return Result.succeed()
  }

  function switchBranch(branch: Branch) {
    setSession({
      user: session.user!,
      branches: session.branches!,
      currentBranch: branch,
      loading: false,
    })
  }

  async function deleteBranch(
    branchId: string
  ): Promise<
    Success<void> | Failure<void, UnauthenticatedUserError | UnexpectedError>
  > {
    if (!session.user) return Result.fail(new UnauthenticatedUserError())
    const result = await deleteBranchUseCase({
      company: session.user.company.id,
      cnpj: branchId,
    })
    if (result.isFailure) return Result.fail(result.error!)
    const branches = session.branches.filter(({ id }) => id !== branchId)
    const currentBranch =
      session.currentBranch.id === branchId
        ? branches[0]
        : session.currentBranch

    setSession({
      ...session,
      branches,
      currentBranch,
    })
    return Result.succeed()
  }

  const [useSessionListener, emitSessionChangedEvent] = createEvent<Session>()

  let authStateListenerDestroyer = createAuthStateListener(onAuthStateChanged)

  async function onAuthStateChanged(userCredential: UserCredential | null) {
    if (!userCredential) {
      setSession({ loading: false })
      return
    }

    try {
      const userRepository = getUserRepository()
      const companyRepository = getCompanyRepository()
      const branchRepository = getBranchRepository()

      const user = await userRepository.get(userCredential.uid)
      if (!user) return

      const company = await companyRepository.get(user.company)
      if (!company) return

      const branches = await branchRepository.getAll(company.id)

      const newSession = {
        user: {
          id: user.id,
          name: user.name,
          email: user.email,
          emailVerified: userCredential.emailVerified,
          company,
        },
        branches,
        currentBranch: branches[0],
        loading: false,
      }
      setSession(newSession)
      emitSessionChangedEvent(newSession)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <SessionContext.Provider
      value={{
        session,
        login,
        signup,
        applyEmailVerificationCode,
        addBranch,
        switchBranch,
        deleteBranch,
        useSessionListener,
      }}
    >
      {session.loading ? <SessionLoader /> : children}
    </SessionContext.Provider>
  )
}
