import { FirestoreRepository } from '@/common/infra/firebase/firestore/firestore.repository'
import { Company } from '../../models/company'
import { CompanyConverter } from './company.converter'
import { singleton } from '@lib/singleton'
import { ICompanyRepository } from '../../repositories/company.repository'

export class CompanyRepository
  extends FirestoreRepository<Company, string>
  implements ICompanyRepository
{
  protected collection = 'companies'
  protected converter = new CompanyConverter()
}

export const getCompanyRepository = singleton(() => new CompanyRepository())
