import { Driver } from '@/driver/models/driver'
import { DriverConverter } from '../converters/driver.converter'
import { singleton } from '~/lib/singleton'
import { SubcollectionRepository } from '@/common/infra/firebase/firestore/subcollection.repository'

export class DriversRepository extends SubcollectionRepository<Driver, string> {
  protected subcollection = 'drivers'
  protected collection = 'companies'
  protected converter = new DriverConverter()
}

export const getDriversRepository = singleton(() => new DriversRepository())
