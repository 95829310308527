import { Failure, PossibleResults, Result, Success } from '~/lib/logic/result'
import { UnexpectedError } from '@/common/errors/unexpected.error'
import { getVehiclesRepository } from '../infra/firebase/repositories/vehicle.repository'

export type DeleteVehiclePossibleResults = PossibleResults<
  Success<void>,
  Failure<void, UnexpectedError>
>

export async function deleteVehicle(company: string, plate: string) {
  try {
    const vehiclesRepository = getVehiclesRepository()
    await vehiclesRepository.delete(company, plate)
    return Result.succeed()
  } catch (e) {
    return Result.fail(new UnexpectedError(e as Error))
  }
}
