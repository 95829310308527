import { Optional } from '@lib/type-utils/optional'
import { MaybeWithTimestamps, Model, Stringfiable } from '@/common/model'
import { UniqueId } from '@/common/unique-id'
import { DocumentData, Timestamp, serverTimestamp } from 'firebase/firestore'

export type TimestampedDocument<
  DbModelType extends DocumentData = DocumentData
> = DbModelType & {
  createdAt: Timestamp
  updatedAt: Timestamp
}

export type MaybeTimestampedDocument = Optional<
  'createdAt' | 'updatedAt',
  TimestampedDocument
>

export abstract class FirestoreConverter<
  M extends Model<ID>,
  ID extends Stringfiable = UniqueId
> {
  abstract convertModel(model: M): DocumentData
  abstract convertDocument(id: string, doc: DocumentData): M

  fromFirestore(
    id: string,
    data: MaybeTimestampedDocument
  ): MaybeWithTimestamps<M, ID> {
    const model: MaybeWithTimestamps<M, ID> = this.convertDocument(id, data)
    model.createdAt = data.createdAt?.toDate()
    model.updatedAt = data.updatedAt?.toDate()
    return model
  }

  toFirestore(model: M) {
    const data = this.convertModel(model)
    return {
      ...data,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    }
  }
}
