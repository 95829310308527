import { useOutletContext } from 'react-router-dom'
import { MainLayoutOutletContextValue } from '~/components/main-layout'
import { NewUserForm } from '../components/new-user.form'
import { useEffect } from 'react'

export function NewUser() {
  const { setLayoutOptions } = useOutletContext<MainLayoutOutletContextValue>()

  useEffect(() => setLayoutOptions('form', 'cadastar usuário', '', 'primary'))

  return (
    <main className="w-full h-full relative">
      <NewUserForm />
    </main>
  )
}
