import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { Form } from '~/components/ui/form'
import { TextField } from '@components/ui/text-field'
import { Button } from '~/components/ui/button'
import { Link, useNavigate } from 'react-router-dom'
import { useSession } from '@/auth/contexts/session'
import { toast } from 'react-toastify'
import { addUser } from '../../use-cases/add-user'
import { UnexpectedError } from '@/common/errors/unexpected.error'

const requiredString = z.string().min(1, 'Campo obrigatório')

const formSchema = z.object({
  name: requiredString,
  email: requiredString.email(),
  phone: requiredString,
  password: requiredString,
})

export function NewUserForm() {
  const { session } = useSession()

  const navigate = useNavigate()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      password: '',
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const result = await addUser({
      ...values,
      company: session.user!.company.id,
    })

    if (result.isFailure) {
      if (result.error instanceof UnexpectedError) {
        console.error(result.error.cause.stack)
      }
      toast.error(result.error?.message)
      return
    }

    toast.success('Usuário cadastrado com sucesso.')
    navigate(`/editar-usuario/${result.value.id}`)
  }

  return (
    <Form {...form}>
      <form
        className="fixed top-0 -z-10 h-screen w-full flex flex-col bg-background"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="h-full flex flex-row flex-wrap justify-center content-start gap-y-4 gap-x-4 px-8 pb-8 pt-28 border-b-2 rounded-b-3xl overflow-y-auto bg-primary shadow-inner-bottom no-scrollbar">
          <TextField
            className="min-w-52 max-w-80"
            name="name"
            label="Nome"
            form={form}
          />
          <TextField
            className="min-w-52 max-w-80"
            type="email"
            name="email"
            label="E-mail"
            form={form}
          />
          <TextField
            className="min-w-52 max-w-80"
            name="phone"
            label="Telefone"
            form={form}
          />
          <TextField
            className="min-w-52 max-w-80"
            type="password"
            name="password"
            label="Senha"
            form={form}
          />
        </div>
        <div className="bottom-0 w-full flex justify-center px-8 py-5 gap-x-4 bg-background">
          <Link className="w-full" to="/usuarios">
            <Button
              className="w-full rounded-sm shadow-light-sm uppercase tracking-widest"
              variant="outline"
            >
              Cancelar
            </Button>
          </Link>
          <Button
            className="w-full rounded-sm shadow-light-sm uppercase tracking-widest"
            type="submit"
          >
            Cadastrar
          </Button>
        </div>
      </form>
    </Form>
  )
}
