import { MainLayoutOutletContextValue } from '@components/main-layout'
import { Button } from '@components/ui/button'
import { DataGrid } from '@components/ui/data-grid'
import PencilIcon from '@components/ui/icons/pencil-icon'
import PlusIcon from '@components/ui/icons/plus-icon'
import TrashIcon from '@components/ui/icons/trash-icon'
import { useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { getDriversRepository } from '../../infra/firebase/repositories/driver.repository'
import { MaybeWithTimestamps } from '@/common/model'
import { Driver as DriverModel } from '../../models/driver'
import { useSession } from '@/auth/contexts/session'
import { deleteDriver } from '../../use-cases/delete-driver'
import { toast } from 'react-toastify'

export function Drivers() {
  const { setPageTitle, setMode, setBackgroundColor } =
    useOutletContext<MainLayoutOutletContextValue>()

  setPageTitle('Motoristas')
  setMode('title')
  setBackgroundColor('background')

  const { session } = useSession()

  const [drivers, setDrivers] = useState<
    MaybeWithTimestamps<DriverModel, string>[]
  >([])
  const [loading, setLoading] = useState(true)

  const driversRepository = getDriversRepository()
  useEffect(() => {
    driversRepository
      .getAll(session.user!.company.id)
      .then(setDrivers)
      .finally(() => setLoading(false))
  }, [session.user, driversRepository])

  async function handleDelete(driver: DriverModel) {
    const result = await deleteDriver(session.user!.company.cnpj, driver.cpf)
    if (result.isFailure) {
      toast.error('Erro inesperado ao deletar veículo.')
      console.error(result.error!.cause.stack)
    }
    setDrivers(drivers.filter(({ cpf }) => cpf !== driver.cpf))
    toast.success('Veículo deletado com sucesso')
  }

  return (
    <main className="p-8">
      <div className="w-full flex justify-end pb-8">
        <Link to="/novo-motorista">
          <Button className="uppercase font-medium tracking-widest text-xs gap-x-2 shadow-light-sm rounded-sm">
            <PlusIcon color="primary-foreground" size="xs" />
            Novo motorista
          </Button>
        </Link>
      </div>
      <DataGrid
        items={drivers}
        getKey={(driver) => driver.cpf}
        renderHeader={(driver) => (
          <div className="w-full">
            <div className="w-full grid grid-cols-[2fr_2fr_2fr] content-center">
              <span></span>
              <span className="place-self-center">{driver.name}</span>
              <div className="flex gap-3 place-self-end">
                <Link to={`/editar-motorista/${driver.cpf}`}>
                  <Button className="h-6 w-6 p-1 rounded-full shadow-center">
                    <PencilIcon color="primary-foreground" size="xs" />
                  </Button>
                </Link>
                <Button
                  className="h-6 w-6 p-1 rounded-full shadow-center"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDelete(driver)
                  }}
                  variant="destructive"
                >
                  <TrashIcon color="primary-foreground" size="xs" />
                </Button>
              </div>
            </div>
            <div className="w-full flex flex-col pt-6 px-5 gap-y-2 text-sm">
              <div className="flex justify-between">
                <span className="text-muted-foreground">CPF</span>
                <span>{driver.cpf}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-muted-foreground">RG</span>
                <span>{driver.rg}</span>
              </div>
            </div>
          </div>
        )}
      />
    </main>
  )
}
