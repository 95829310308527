import { Model } from '@/common/model'

export class Branch extends Model<string> {
  constructor(
    cnpj: string,
    public name: string,
    public readonly digitalCertificatePassword?: string
  ) {
    cnpj = cnpj.replace(/\D/g, '')
    super(cnpj)
  }

  public get cnpj(): string {
    return (
      this.id.substring(0, 2) +
      `.${this.id.substring(2, 5)}` +
      `.${this.id.substring(5, 8)}` +
      `/${this.id.substring(8, 12)}` +
      `-${this.id.substring(12, 14)}`
    )
  }
}
