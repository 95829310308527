import {
  EMAIL_EXISTS,
  INVALID_LOGIN_CREDENTIALS,
  INVALID_PASSWORD,
  REJECTED_CREDENTIAL,
  TOO_MANY_ATTEMPTS_TRY_LATER,
} from '@lib/firebase/auth/error-codes'
import { firebase } from '@lib/firebase'
import { Result } from '@lib/logic/result'
import { EmailAlreadyRegisteredError } from '../../../errors/email-already-registered.error'
import { InvalidPasswordError } from '../../../errors/invalid-password.error'
import { UnexpectedError } from '../../../../common/errors/unexpected.error'
import {
  IAuthService,
  SigninResult,
  AuthSignupResult,
  SendEmailVerificationResult,
  CodeVerificationResult,
  ReauthenticateWithCredentialResult,
  SendPasswordRecoveryEmailResult,
} from '../../../services/auth.service'
import {
  Auth,
  EmailAuthProvider,
  applyActionCode,
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  updateCurrentUser,
  updatePassword,
  updateProfile,
} from 'firebase/auth'
import { AuthSignupDto } from '../../../dto/auth-signup.dto'
import { UserCredential } from '../../../models/user-credential'
import { singleton } from '@lib/singleton'
import { UnauthenticatedUserError } from '../../../errors/unauthenticated-user.error'
import { InvalidCredentialsError } from '../../../errors/invalid-credentials.error'
import {
  CompanyRepository,
  getCompanyRepository,
} from '../../../../company/infra/firebase/company.repository'
import { TooManyLoginAttemptsError } from '@/auth/errors/too-many-login-attemps.error'

class AuthService implements IAuthService {
  private readonly firebaseAuth: Auth
  private readonly companyRepository: CompanyRepository

  constructor() {
    const { auth } = firebase()
    this.companyRepository = getCompanyRepository()
    this.firebaseAuth = auth
  }

  public async signup(dto: AuthSignupDto): Promise<AuthSignupResult> {
    try {
      const { currentUser } = this.firebaseAuth

      const { user } = await createUserWithEmailAndPassword(
        this.firebaseAuth,
        dto.email,
        dto.password
      )

      await updateProfile(user, { displayName: dto.name })

      const actionCodeSettings = {
        url: `${process.env.REACT_APP_URL}`,
        handleCodeInApp: true,
      }
      sendEmailVerification(user, actionCodeSettings)

      if (currentUser) {
        updateCurrentUser(this.firebaseAuth, currentUser)
      }

      return Result.succeed(UserCredential.fromFirebase(user))
    } catch (error: any) {
      switch (error.code) {
        case EMAIL_EXISTS:
          return Result.fail(new EmailAlreadyRegisteredError(dto.email))
        case INVALID_PASSWORD:
          return Result.fail(new InvalidPasswordError())
        default:
          return Result.fail(
            new UnexpectedError(error, 'Erro inesperado ao cadastrar usuário: ')
          )
      }
    }
  }

  public async signin(
    email: string,
    password: string,
    persistSession: boolean
  ): Promise<SigninResult> {
    try {
      setPersistence(
        this.firebaseAuth,
        persistSession ? browserLocalPersistence : browserSessionPersistence
      )

      const { user } = await signInWithEmailAndPassword(
        this.firebaseAuth,
        email,
        password
      )

      return Result.succeed(UserCredential.fromFirebase(user))
    } catch (error: any) {
      switch (error.code) {
        case INVALID_LOGIN_CREDENTIALS:
          return Result.fail(new InvalidCredentialsError())
        case TOO_MANY_ATTEMPTS_TRY_LATER:
          return Result.fail(new TooManyLoginAttemptsError())
        default:
          return Result.fail(
            new UnexpectedError(error, 'Erro inesperado ao autenticar usuário')
          )
      }
    }
  }

  public async signout() {
    await signOut(this.firebaseAuth)
  }

  public async getCurrentUser(): Promise<UserCredential | null> {
    const user = this.firebaseAuth.currentUser
    if (!user) return user
    return UserCredential.fromFirebase(user)
  }

  public async sendEmailVerification(): Promise<SendEmailVerificationResult> {
    if (!this.firebaseAuth.currentUser) {
      return Result.fail(new UnauthenticatedUserError())
    }
    sendEmailVerification(this.firebaseAuth.currentUser)
    return Result.succeed()
  }

  public async applyVerificationCode(
    code: string
  ): Promise<CodeVerificationResult> {
    if (!this.firebaseAuth.currentUser) {
      return Result.fail(new UnauthenticatedUserError())
    }

    return applyActionCode(this.firebaseAuth, code)
      .then(() => this.firebaseAuth.currentUser!.reload())
      .then(() => Result.succeed(this.firebaseAuth.currentUser!.emailVerified))
      .catch((e) => Result.fail(new UnexpectedError(e)))
  }

  public async sendSignInLink(email: string): Promise<void> {
    await sendSignInLinkToEmail(this.firebaseAuth, email, {
      url: `${process.env.REACT_APP_URL}/email-link-login`,
    })
  }

  public async changePassword(password: string): Promise<void> {
    if (!this.firebaseAuth.currentUser) {
      throw new UnauthenticatedUserError()
    }
    await updatePassword(this.firebaseAuth.currentUser, password)
  }

  public async reauthenticateWithCredential(
    password: string
  ): Promise<ReauthenticateWithCredentialResult> {
    try {
      const user = this.firebaseAuth.currentUser
      if (!user) {
        return Result.fail(new UnauthenticatedUserError())
      }
      const credential = EmailAuthProvider.credential(user.email!, password)
      await reauthenticateWithCredential(user, credential)
      return Result.succeed()
    } catch (error: any) {
      switch (error.code) {
        case INVALID_LOGIN_CREDENTIALS:
        case REJECTED_CREDENTIAL:
          return Result.fail(new InvalidCredentialsError())
        case TOO_MANY_ATTEMPTS_TRY_LATER:
          return Result.fail(new TooManyLoginAttemptsError())
        default:
          return Result.fail(new UnexpectedError(error as Error))
      }
    }
  }

  public async sendPasswordRecoveryEmail(
    email: string
  ): Promise<SendPasswordRecoveryEmailResult> {
    return sendPasswordResetEmail(this.firebaseAuth, email)
      .then(() => Result.succeed())
      .catch((e) => Result.fail(e))
  }
}

export const getAuthService = singleton(() => new AuthService())
