import { UniqueId } from './unique-id'

export type Stringfiable = {
  toString(): string
}

export class Model<ID extends Stringfiable = UniqueId> {
  constructor(public readonly id: ID) {}
}

export type WithTimestamps<
  M extends Model<ID>,
  ID extends Stringfiable = UniqueId
> = M & {
  createdAt: Date
  updatedAt: Date
}

export type MaybeWithTimestamps<
  M extends Model<ID>,
  ID extends Stringfiable = UniqueId
> = M & {
  createdAt?: Date
  updatedAt?: Date
}
