import { getAuthService } from '../infra/firebase/services/auth.service'
import { ensureWillSucceed } from '@lib/logic/utils'
import { DomainError } from '../../common/errors/domain.error'
import { Failure, PossibleResults, Result, Success } from '@lib/logic/result'
import { UnexpectedError } from '../../common/errors/unexpected.error'
import { InvalidCredentialsError } from '../errors/invalid-credentials.error'
import { UserCredential } from '../models/user-credential'

export type SigninFailure =
  | Failure<UserCredential, InvalidCredentialsError>
  | Failure<UserCredential, UnexpectedError>

export type SigninResult = PossibleResults<
  Success<UserCredential>,
  SigninFailure
>

export async function signin(
  email: string,
  password: string,
  persistSession: boolean
): Promise<SigninResult> {
  try {
    const authService = getAuthService()
    const user = await ensureWillSucceed(
      authService.signin(email, password, persistSession)
    )
    return Result.succeed(user)
  } catch (e) {
    if (e instanceof DomainError) {
      return Result.fail(e) as SigninFailure
    }
    return Result.fail(new UnexpectedError(e as Error))
  }
}
