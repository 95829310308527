import { UserCredential } from '@/auth/models/user-credential'
import { firebase } from '@lib/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { createEvent } from '@lib/event'

const { auth } = firebase()

export const [createAuthStateListener, emitAuthStateChangedEvent] =
  createEvent<UserCredential | null>()

onAuthStateChanged(auth, async (user) => {
  emitAuthStateChangedEvent(user && UserCredential.fromFirebase(user))
})
