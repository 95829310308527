import { User } from 'firebase/auth'

export class UserCredential {
  constructor(
    public readonly uid: string,
    public email: string,
    public emailVerified: boolean,
    public name: string
  ) {}

  static fromFirebase(user: User): UserCredential {
    return new UserCredential(
      user.uid,
      user.email!,
      user.emailVerified,
      user.displayName!
    )
  }
}
