import { MainLayoutOutletContextValue } from '@components/main-layout'
import { useOutletContext } from 'react-router-dom'
import { NewVehicleForm } from '../components/new-vehicle-form'

export function NewVehicle() {
  const { setPageTitle, setPageSubTitle, setBackgroundColor, setMode } =
    useOutletContext<MainLayoutOutletContextValue>()

  setMode('form')
  setPageTitle('Novo Veículo')
  setPageSubTitle('')
  setBackgroundColor('primary')

  return (
    <main className="w-full h-full relative">
      <NewVehicleForm />
    </main>
  )
}
