import { Failure, PossibleResults, Result, Success } from '~/lib/logic/result'
import {
  TransportType,
  Vehicle,
  VehicleBody,
  VehicleType,
  Wheelset,
} from '../models/vehicle'
import { UnexpectedError } from '@/common/errors/unexpected.error'
import { EditVehicleDto } from '../dto/edit-vehicle.dto'
import { getVehiclesRepository } from '../infra/firebase/repositories/vehicle.repository'

export type EditVehiclePossibleResults = PossibleResults<
  Success<Vehicle>,
  Failure<Vehicle, UnexpectedError>
>

export async function editVehicle(
  dto: EditVehicleDto
): Promise<EditVehiclePossibleResults> {
  try {
    const vehiclesRepository = getVehiclesRepository()

    const vehicle = new Vehicle(
      dto.plate,
      dto.name,
      dto.renavam,
      dto.transportType as TransportType,
      dto.vehicleType as VehicleType,
      dto.body as VehicleBody,
      dto.wheelset as Wheelset,
      dto.tare,
      dto.massCapacity,
      dto.volumeCapacity,
      dto.state,
      dto.brand,
      dto.model
    )

    await vehiclesRepository.update(dto.company, vehicle)

    return Result.succeed(vehicle)
  } catch (e) {
    return Result.fail(new UnexpectedError(e as Error))
  }
}
