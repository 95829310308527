import { ChangeEvent } from 'react'
import {
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormReturn,
} from 'react-hook-form'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './form'
import { cn } from '~/lib/utils'
import { Input } from './input'

export function FileField<FormSchema extends FieldValues>({
  className,
  form,
  name,
  label,
  variant = 'default',
  onValueChange = () => {},
}: {
  form: UseFormReturn<FormSchema, any, undefined>
  className?: string
  name: FieldPath<FormSchema>
  label: string
  variant?: 'default' | 'secondary'
  onValueChange?: (file?: File) => void
}) {
  function onChangeHandler(
    field: ControllerRenderProps<FormSchema, FieldPath<FormSchema>>
  ) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      field.onChange(file)
      onValueChange(file)
    }
  }

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn('w-full', className)}>
          <FormLabel
            className={cn(
              'text-sm font-light',
              variant === 'default'
                ? 'text-primary-foreground'
                : 'text-foreground'
            )}
          >
            {label}
          </FormLabel>
          <FormControl>
            <Input
              ref={field.ref}
              name={field.name}
              onBlur={field.onBlur}
              disabled={field.disabled}
              className="border-primary text-foreground"
              type="file"
              onChange={onChangeHandler(field)}
            />
          </FormControl>
          <FormMessage className="text-xs text-destructive-foreground font-medium" />
        </FormItem>
      )}
    />
  )
}
