import { Branch } from '../../models/branch'
import { BranchConverter } from './branch.converter'
import { singleton } from '@lib/singleton'
import { SubcollectionRepository } from '@/common/infra/firebase/firestore/subcollection.repository'

export class BranchRepository extends SubcollectionRepository<Branch, string> {
  protected collection = 'companies'
  protected subcollection = 'branches'
  protected converter = new BranchConverter()
}

export const getBranchRepository = singleton(() => new BranchRepository())
