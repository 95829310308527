import { Model } from '../../common/model'

const equal = (value: string) => (maybeEqual: string) => maybeEqual === value

export enum CompanyType {
  TRANSPORTER = 'transportador',
  PROVIDER = 'fornecedor',
}

export class Company extends Model<string> {
  private _users: string[]

  constructor(
    cnpj: string,
    public readonly name: string,
    public readonly type: CompanyType,
    users: string[]
  ) {
    cnpj = cnpj.replace(/\D/g, '')
    super(cnpj)
    this._users = users
  }

  public get users(): string[] {
    return [...this._users]
  }

  public get cnpj(): string {
    return (
      this.id.substring(0, 2) +
      `.${this.id.substring(2, 5)}` +
      `.${this.id.substring(5, 8)}` +
      `/${this.id.substring(8, 12)}` +
      `-${this.id.substring(12, 14)}`
    )
  }

  public addUser(user: string): void {
    if (this._users.find(equal(user))) return
    this._users.push(user)
  }

  public removeUser(user: string): void {
    const userIndex = this._users.findIndex(equal(user))
    if (userIndex === -1) return
    this._users.splice(userIndex, 1)
  }
}
