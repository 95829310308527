import { UnexpectedError } from '@/common/errors/unexpected.error'
import { Result } from '@lib/logic/result'
import { getAuthService } from '../infra/firebase/services/auth.service'
import { CodeVerificationResult } from '../services/auth.service'

const authService = getAuthService()

export function applyVerificationCode(
  code: string
): Promise<CodeVerificationResult> {
  return authService
    .applyVerificationCode(code)
    .then((result) => result)
    .catch((e) => Result.fail(new UnexpectedError(e)))
}
