import React from 'react'
import SVGIcon from './svg-icon'

const PlusIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>(({ className, ...props }, ref) => {
  return (
    <SVGIcon ref={ref} {...props} viewBox="0 0 10.5 10.5">
      <path d="M11.25 6.25H6.75V10.75H5.25V6.25H0.75V4.75H5.25V0.25H6.75V4.75H11.25V6.25Z" />
    </SVGIcon>
  )
})

PlusIcon.displayName = 'PlusIcon'

export default PlusIcon
