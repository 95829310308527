import React from 'react'
import SVGIcon from './svg-icon'

const ArrowRightIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>(({ className, ...props }, ref) => {
  return (
    <SVGIcon className={className} ref={ref} {...props} viewBox="0 0 28 27">
      <path d="M21.172 11.3138L13.272 3.41384C12.9077 3.03663 12.7061 2.53143 12.7107 2.00704C12.7152 1.48264 12.9255 0.981017 13.2964 0.610201C13.6672 0.239384 14.1688 0.0290465 14.6932 0.0244897C15.2176 0.0199328 15.7228 0.221522 16.1 0.585838L27.414 11.8998C27.6004 12.0851 27.7483 12.3055 27.8492 12.5481C27.9501 12.7908 28.0021 13.051 28.0021 13.3138C28.0021 13.5767 27.9501 13.8369 27.8492 14.0795C27.7483 14.3222 27.6004 14.5425 27.414 14.7278L16.1 26.0418C15.9155 26.2329 15.6948 26.3852 15.4508 26.49C15.2068 26.5949 14.9444 26.65 14.6788 26.6523C14.4132 26.6546 14.1499 26.604 13.9041 26.5035C13.6583 26.4029 13.435 26.2544 13.2472 26.0666C13.0594 25.8788 12.9109 25.6555 12.8104 25.4097C12.7098 25.164 12.6592 24.9006 12.6615 24.635C12.6638 24.3695 12.719 24.107 12.8238 23.863C12.9286 23.619 13.081 23.3983 13.272 23.2138L21.172 15.3138H2C1.46957 15.3138 0.960859 15.1031 0.585787 14.7281C0.210714 14.353 0 13.8443 0 13.3138C0 12.7834 0.210714 12.2747 0.585787 11.8996C0.960859 11.5246 1.46957 11.3138 2 11.3138H21.172Z" />
    </SVGIcon>
  )
})

ArrowRightIcon.displayName = 'ArrowRightIcon'

export default ArrowRightIcon
