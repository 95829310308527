import { MaybeWithTimestamps, Model, Stringfiable } from '@/common/model'
import { IRepository } from '@/common/repository'
import { firebase } from '@lib/firebase'
import {
  Firestore,
  QueryConstraint,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {
  FirestoreConverter,
  MaybeTimestampedDocument,
} from './firestore.converter'
import { UniqueId } from '@/common/unique-id'

export abstract class FirestoreRepository<
  M extends Model<ID>,
  ID extends Stringfiable = UniqueId
> implements IRepository<M, ID>
{
  protected abstract readonly collection: string
  protected abstract readonly converter: FirestoreConverter<M, ID>
  protected readonly firestore: Firestore

  constructor() {
    const { db } = firebase()
    this.firestore = db
  }

  async add(model: M): Promise<void> {
    const ref = this.getRef(model.id.toString())
    await setDoc(ref, this.converter.toFirestore(model))
  }

  async get(id: string): Promise<MaybeWithTimestamps<M, ID> | null> {
    const ref = this.getRef(id)
    const docSnap = await getDoc(ref)
    return docSnap.exists()
      ? this.converter.fromFirestore(
          docSnap.id,
          docSnap.data() as MaybeTimestampedDocument
        )
      : null
  }

  async update(model: M): Promise<void> {
    const ref = this.getRef(model.id.toString())
    await updateDoc(ref, this.converter.toFirestore(model))
  }

  async delete(id: string): Promise<void> {
    const ref = this.getRef(id)
    await deleteDoc(ref)
  }

  protected getRef(id: string) {
    return doc(this.firestore, this.collection, id)
  }

  protected async query(...queryConstraints: QueryConstraint[]): Promise<M[]> {
    const q = query(
      collection(this.firestore, this.collection),
      ...queryConstraints
    )
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc) =>
      this.converter.fromFirestore(
        doc.id,
        doc.data() as MaybeTimestampedDocument
      )
    )
  }
}
