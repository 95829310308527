export type EventListener<T> = (value: T) => any
export type ListenerDestroyer = () => void
export type CreateEventReturn<T> = [
  (listener: EventListener<T>) => ListenerDestroyer,
  (value: T) => void
]

export function createEvent<T>(): CreateEventReturn<T> {
  const listeners: EventListener<T>[] = []
  const useListener = (listener: EventListener<T>) => {
    let listenerIndex = listeners.push(listener) - 1
    return () => {
      // ensure it runs only once and doesn't destroy other listeners on unwanted re-runs
      if (listenerIndex > 0) {
        listeners.splice(listenerIndex, 1)
        listenerIndex = -1
      }
    }
  }

  const emit = (value: T) => listeners.forEach((listener) => listener(value))
  return [useListener, emit]
}
