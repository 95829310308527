import React from 'react'
import SVGIcon from './svg-icon'

const ExitIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>(({ className, ...props }, ref) => {
  return (
    <SVGIcon ref={ref} {...props} viewBox="0 0 17 22">
      <path d="M9.34 7.17C8.41 7.17 7.65 6.4 7.65 5.47C7.65 5.02178 7.82805 4.59193 8.14499 4.27499C8.46193 3.95805 8.89178 3.78 9.34 3.78C10.28 3.78 11.04 4.54 11.04 5.47C11.04 6.4 10.28 7.17 9.34 7.17ZM6.3 18.93L0.37 17.75L0.71 16.05L4.86 16.9L6.21 10.04L4.69 10.64V13.5H3V9.54L7.4 7.67L8.07 7.59C8.67 7.59 9.17 7.93 9.5 8.44L10.36 9.79C11.04 11 12.39 11.82 14 11.82V13.5C12.14 13.5 10.44 12.67 9.34 11.4L8.84 13.94L10.61 15.63V22H8.92V16.9L7.14 15.21L6.3 18.93ZM17 22H15V2H2V15.11L0 14.69V0H17V22ZM2 22H0V18.78L2 19.2V22Z" />
    </SVGIcon>
  )
})

ExitIcon.displayName = 'ExitIcon'

export default ExitIcon
