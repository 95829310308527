import { Failure, PossibleResults, Result, Success } from '~/lib/logic/result'
import { UnexpectedError } from '@/common/errors/unexpected.error'
import { getDriversRepository } from '../infra/firebase/repositories/driver.repository'

export type DeleteDriverPossibleResults = PossibleResults<
  Success<void>,
  Failure<void, UnexpectedError>
>

export async function deleteDriver(company: string, plate: string) {
  try {
    const driversRepository = getDriversRepository()
    await driversRepository.delete(company, plate)
    return Result.succeed()
  } catch (e) {
    return Result.fail(new UnexpectedError(e as Error))
  }
}
