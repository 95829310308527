import { MainLayoutMode } from './main-layout'
import { SidebarToggle } from './sidebar-toggle'
import Logo from './ui/logo'

function LogoHeader() {
  return (
    <header className="w-full flex justify-between items-center p-8">
      <Logo collapsed size="xl" />
      <span className="tracking-widest text-lg text-primary font-normal uppercase">
        AUTONOMOUS
      </span>
      <SidebarToggle />
    </header>
  )
}

function TitleHeader({ title }: { title?: string }) {
  return (
    <header className="w-full flex justify-between items-center px-8 pt-8 pb-5">
      <span className="tracking-widest text-lg text-primary font-normal uppercase">
        {title}
      </span>
      <SidebarToggle />
    </header>
  )
}

function FormHeader({
  title,
  subtitle,
}: {
  title?: string
  subtitle?: string
}) {
  return (
    <header className="bg-background w-full flex justify-between px-8 pt-8 pb-5 rounded-b-3xl shadow-light-sm">
      <div className="flex flex-col">
        <span className="tracking-widest text-lg text-primary font-normal uppercase">
          {title}
        </span>
        <span className="tracking-widest text-xs text-muted-foreground font-medium uppercase">
          {subtitle}
        </span>
      </div>
      <SidebarToggle />
    </header>
  )
}

export function MainHeader({
  mode = 'logo',
  title,
  subtitle,
}: {
  mode?: MainLayoutMode
  title?: string
  subtitle?: string
}) {
  switch (mode) {
    case 'title':
      return <TitleHeader title={title} />
    case 'form':
      return <FormHeader title={title} subtitle={subtitle} />
    case 'logo':
    default:
      return <LogoHeader />
  }
}
