import { useSession } from '@/auth/contexts/session'
import { Button } from './ui/button'
import { cn } from '~/lib/utils'
import { createContext, useContext, useState } from 'react'
import { NewBranchForm } from '@/company/views/components/new-branch.form'
import TrashIcon from './ui/icons/trash-icon'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '~/@components/ui/tooltip'
import { toast } from 'react-toastify'

export type BranchMenuContextValue = {
  formIsOpen: boolean
  openForm: () => void
  closeForm: () => void
}

const BranchMenuContext = createContext<BranchMenuContextValue>({
  formIsOpen: false,
  openForm: () => {},
  closeForm: () => {},
})

export function useBranchMenuContext() {
  return useContext(BranchMenuContext)
}

export function BranchMenu() {
  const { session, switchBranch, deleteBranch } = useSession()
  const [formIsOpen, setFormIsOpen] = useState(false)

  const openForm = () => setFormIsOpen(true)
  const closeForm = () => setFormIsOpen(false)

  const branchList = session.branches!.map((branch) => {
    const isCurrent = branch.id === session.currentBranch!.id

    return (
      <Button
        key={branch.id}
        className={cn(
          'bg-foreground w-full flex items-center justify-between gap-x-5 px-4 py-6 hover:bg-foreground hover:brightness-125 hover:text-primary-foreground',
          isCurrent ? 'brightness-125 hover:cursor-default' : ''
        )}
        variant="ghost"
        onClick={() => !isCurrent && switchBranch(branch)}
      >
        <div className="flex flex-col items-start text-primary-foreground tracking-widest font-semibold uppercase">
          <span className="leading-none">{branch.name}</span>
          <span className="leading-none text-[#768b81] text-xs font-normal">
            {branch.cnpj}
          </span>
        </div>
        {branch.id !== session.user!.company.id && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  className="h-6 w-6 p-1 rounded-full shadow-center"
                  onClick={async () => {
                    const result = await deleteBranch(branch.id)
                    if (result.isFailure) {
                      toast.error(result.error!.message)
                    }
                    toast.success(`Filial ${branch.name} deletada.`)
                  }}
                  variant="destructive"
                >
                  <TrashIcon color="primary-foreground" size="xs" />
                </Button>
              </TooltipTrigger>
              <TooltipContent className="border-none bg-foreground brightness-75 text-primary-foreground text-xs">
                <p>Deletar filial</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </Button>
    )
  })

  return (
    <BranchMenuContext.Provider value={{ formIsOpen, openForm, closeForm }}>
      <div className="flex flex-col h-full pb-8 gap-y-4">
        <span className="tracking-wider text-primary-foreground">FILIAIS</span>
        <div className="flex flex-col gap-y-4 h-full overflow-y-auto no-scrollbar">
          {branchList.length ? branchList : 'Não há nenhuma'}
        </div>
        <Button
          className="uppercase tracking-wider"
          variant="secondary"
          onClick={() => openForm()}
        >
          Cadastrar filial
        </Button>
        <div
          className={cn(
            'transition-all absolute bottom-0 flex flex-col w-full h-full bg-primary px-4 py-4 rounded-s-3xl shadow-t-md overflow-x-visible overflow-y-auto no-scrollbar',
            formIsOpen ? 'left-0' : 'left-[101%]'
          )}
        >
          <div className="flex flex-col">
            <NewBranchForm />
          </div>
        </div>
      </div>
    </BranchMenuContext.Provider>
  )
}
