import { Failure, PossibleResults, Result, Success } from '~/lib/logic/result'
import { UnauthorizedError } from '@/common/errors/unauthorized.error'
import { UnexpectedError } from '@/common/errors/unexpected.error'
import { InvalidPasswordError } from '../errors/invalid-password.error'
import { getAuthService } from '../infra/firebase/services/auth.service'
import { UnauthenticatedUserError } from '../errors/unauthenticated-user.error'

export type ChangePasswordResult = PossibleResults<
  Success<void>,
  Failure<void, InvalidPasswordError | UnauthorizedError | UnexpectedError>
>

export async function changePassword(
  newPassword: string
): Promise<ChangePasswordResult> {
  try {
    const authService = getAuthService()
    await authService.changePassword(newPassword)
    return Result.succeed()
  } catch (e) {
    if (e instanceof UnauthenticatedUserError) {
      return Result.fail(e)
    }
    return Result.fail(new UnexpectedError(e as Error))
  }
}
