import React from 'react'

export type SVGIconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
export type SVGIconSizes = { [k in SVGIconSize]: number | [number, number] }

export type SVGIconProps = {
  color?: 'primary' | 'secondary' | string
  size?: SVGIconSize
  sizes?: SVGIconSizes
}

const defaults: { size: SVGIconSize; sizes: SVGIconSizes } = {
  size: 'lg',
  sizes: {
    xs: 12,
    sm: 16,
    md: 24,
    lg: 32,
    xl: 48,
    '2xl': 56,
    '3xl': 64,
  },
}

const SVGIcon = React.forwardRef<
  SVGElement,
  React.SVGAttributes<SVGElement> & SVGIconProps
>(({ children, color, size, sizes, ...props }, ref) => {
  sizes ??= defaults.sizes

  const sizeValues = sizes[size ?? defaults.size]
  const [width, height] = Array.isArray(sizeValues)
    ? sizeValues
    : [sizeValues, sizeValues]

  color ??= 'primary'
  const fill = color.startsWith('#') ? color : `hsl(var(--${color}))`

  return (
    <svg
      {...props}
      width={`${width}px`}
      height={`${height}px`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  )
})

SVGIcon.displayName = 'SVGIcon'

export default SVGIcon
