import { useLoaderData, useOutletContext } from 'react-router-dom'
import { User } from '../../models/user'
import { MainLayoutOutletContextValue } from '~/components/main-layout'
import { EditUserForm } from '../components/edit-user.form'

export function EditUser() {
  const { user } = useLoaderData() as { user: User }

  const { setLayoutOptions } = useOutletContext<MainLayoutOutletContextValue>()
  setLayoutOptions('form', 'Editar usuário', user.name, 'primary')

  return <main className="w-full h-full relative"><EditUserForm /></main>
}
