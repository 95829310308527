import { Result } from '@lib/logic/result'
import { getAuthService } from '../../auth/infra/firebase/services/auth.service'
import { ensureWillSucceed } from '@lib/logic/utils'
import { DomainError } from '../../common/errors/domain.error'
import { UnexpectedError } from '../../common/errors/unexpected.error'
import { SendEmailVerificationResult } from '../../auth/services/auth.service'

export async function sendVerificationCode(): Promise<SendEmailVerificationResult> {
  const authService = getAuthService()

  try {
    await ensureWillSucceed(authService.sendEmailVerification())
    return Result.succeed()
  } catch (e) {
    if (e instanceof DomainError) {
      return Result.fail(e)
    }
    return Result.fail(new UnexpectedError(e as Error))
  }
}
