export class Result<T, E extends Error = Error> {
  public readonly isSuccess: boolean
  public readonly error?: E
  protected readonly _value: T

  public constructor(isSuccess: boolean, error?: E, value?: T) {
    if (isSuccess && error) {
      throw new Error(
        'InvalidOperation: A result cannot be successful and contain an error'
      )
    }
    if (!isSuccess && !error) {
      throw new Error(
        'InvalidOperation: A failing result needs to contain an error object'
      )
    }

    this.isSuccess = isSuccess
    this.error = error
    this._value = value as T
  }

  public get isFailure(): boolean {
    return !this.isSuccess
  }

  public get value(): T {
    if (!this.isSuccess) {
      throw new Error(
        "Can't get the value of an error result. Use 'errorValue' instead. Trying to get value from error result: " +
          this.error
      )
    }

    return this._value
  }

  public static succeed(): Success<void>
  public static succeed<U>(value: U): Success<U>
  public static succeed<U>(value?: U): Success<U | undefined> {
    return new Success(value)
  }

  public static fail<T, E extends Error = Error>(error: E): Failure<T, E> {
    return new Failure(error)
  }

  public static combine(results: Result<any>[]): Result<any> {
    for (const result of results) {
      if (result.isFailure) return result
    }
    return Result.succeed()
  }
}

export class Success<T> extends Result<T> {
  public readonly isSuccess = true
  public readonly error: undefined

  constructor(value: T) {
    super(true, undefined, value)
  }
}

export class Failure<T, E extends Error = Error> extends Result<T, E> {
  constructor(public readonly error: E) {
    super(false, error)
  }
}

export type PossibleResults<S extends Success<any>, F extends Failure<any>> =
  | S
  | F
