import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@components/ui/form'
import { Input } from '@components/ui/input'
import React, { ChangeEvent } from 'react'
import { FieldPath, UseFormReturn, FieldValues } from 'react-hook-form'
import { cn, composeEventHandlers } from '~/lib/utils'

export function TextField<FormSchema extends FieldValues>({
  className,
  form,
  name,
  label,
  type,
  value,
  variant = 'default',
  onChange = () => {},
}: {
  className?: string
  name: FieldPath<FormSchema>
  label: string
  type?: React.HTMLInputTypeAttribute
  variant?: 'default' | 'secondary'
} & (
  | {
      form: UseFormReturn<FormSchema, any, undefined>
      onChange?: (event: ChangeEvent) => void
      readonly?: false
      value?: never
    }
  | {
      form?: never
      onChange?: never
      readOnly: true
      value: string
    }
)) {
  return form ? (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn('w-full', className)}>
          <FormLabel
            className={cn(
              'text-sm font-light',
              variant === 'default'
                ? 'text-primary-foreground'
                : 'text-foreground'
            )}
          >
            {label}
          </FormLabel>
          <FormControl>
            <Input
              className="border-primary text-foreground"
              type={type}
              {...field}
              onChange={composeEventHandlers(field.onChange, onChange)}
            />
          </FormControl>
          <FormMessage className="text-xs text-destructive-foreground font-medium" />
        </FormItem>
      )}
    />
  ) : (
    <div className={cn('space-y-2 w-full', className)}>
      <label className="text-sm text-primary-foreground font-light">
        {label}
      </label>
      <Input
        className="border-primary text-muted-foreground bg-muted"
        type={type}
        readOnly
        value={value}
      />
    </div>
  )
}
