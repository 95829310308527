import React from 'react'
import { useSession } from '@/auth/contexts/session'
import { useRouter } from '@lib/router'
import { RouterProvider } from 'react-router-dom'

function App() {
  const sessionContext = useSession()
  const router = useRouter(sessionContext)

  sessionContext.useSessionListener(() => router.revalidate())

  return <RouterProvider router={router} />
}

export default App
