import { Link, useLoaderData, useOutletContext } from 'react-router-dom'
import { User } from '../../models/user'
import { DataGrid } from '~/components/ui/data-grid'
import { MainLayoutOutletContextValue } from '~/components/main-layout'
import { Button } from '~/components/ui/button'
import PencilIcon from '~/components/ui/icons/pencil-icon'
import TrashIcon from '~/components/ui/icons/trash-icon'
import PlusIcon from '~/components/ui/icons/plus-icon'
import { useEffect } from 'react'

export function Users() {
  const { users } = useLoaderData() as { users: User[] }

  const { setLayoutOptions } = useOutletContext<MainLayoutOutletContextValue>()
  useEffect(() => setLayoutOptions('title', 'Usuários'))

  return (
    <main className="p-8">
      <div className="w-full flex justify-end pb-8">
        <Link to="/novo-usuario">
          <Button className="uppercase font-medium tracking-widest text-xs gap-x-2 shadow-light-sm rounded-sm">
            <PlusIcon color="primary-foreground" size="xs" />
            novo usuário
          </Button>
        </Link>
      </div>
      <DataGrid
        items={users}
        getKey={(user) => user.id}
        searchable={false}
        renderHeader={(user) => (
          <div className="w-full pl-6">
            <div className="w-full flex justify-between">
              <span>{user.name}</span>
              <div className="top-3 right-3 flex gap-3">
                <Link to={`/editar-usuario/${user.id}`}>
                  <Button className="h-6 w-6 p-1 rounded-full shadow-center">
                    <PencilIcon color="primary-foreground" size="xs" />
                  </Button>
                </Link>
                <Button
                  className="h-6 w-6 p-1 rounded-full shadow-center"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  variant="destructive"
                >
                  <TrashIcon color="primary-foreground" size="xs" />
                </Button>
              </div>
            </div>
            <div>
              <span className="text-sm text-muted-foreground leading-none">
                {user.email}
              </span>
            </div>
          </div>
        )}
      />
    </main>
  )
}
