import { useSession } from '@/auth/contexts/session'
import { useEffect, useState } from 'react'
import { EmailVerificationNotice } from '../modules/onboarding/views/components/email-verification-notice'
import { useOutletContext } from 'react-router-dom'
import { MainLayoutOutletContextValue } from './main-layout'

export function Home() {
  const { session } = useSession()

  const [isVerified, setVerified] = useState(!!session.user?.emailVerified)
  useEffect(() => {
    setVerified(!!session.user?.emailVerified)
  }, [session])

  const { setMode, setBackgroundColor } =
    useOutletContext<MainLayoutOutletContextValue>()

  setMode('logo')
  setBackgroundColor('background')

  return (
    <main className="p-14 w-full">
      {!isVerified && <EmailVerificationNotice />}
    </main>
  )
}
