import { Failure, PossibleResults, Result, Success } from '~/lib/logic/result'
import { UnexpectedError } from '@/common/errors/unexpected.error'
import { EditDriverDto } from '../dto/edit-driver.dto'
import { getDriversRepository } from '../infra/firebase/repositories/driver.repository'
import { Driver } from '../models/driver'

export type EditDriverPossibleResults = PossibleResults<
  Success<Driver>,
  Failure<Driver, UnexpectedError>
>

export async function editDriver(
  dto: EditDriverDto
): Promise<EditDriverPossibleResults> {
  try {
    const driversRepository = getDriversRepository()

    const driver = new Driver(dto.cpf, dto.name, dto.rg)

    await driversRepository.update(dto.company, driver)

    return Result.succeed(driver)
  } catch (e) {
    return Result.fail(new UnexpectedError(e as Error))
  }
}
