import React from 'react'
import SVGIcon from './svg-icon'

const CompanyIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>(({ className, ...props }, ref) => {
  return (
    <SVGIcon ref={ref} {...props} viewBox="0 0 20 18">
      <path d="M16.5 12H14.5V14H16.5M16.5 8H14.5V10H16.5M18.5 16H10.5V14H12.5V12H10.5V10H12.5V8H10.5V6H18.5M8.5 4H6.5V2H8.5M8.5 8H6.5V6H8.5M8.5 12H6.5V10H8.5M8.5 16H6.5V14H8.5M4.5 4H2.5V2H4.5M4.5 8H2.5V6H4.5M4.5 12H2.5V10H4.5M4.5 16H2.5V14H4.5M10.5 4V0H0.5V18H20.5V4H10.5Z" />
    </SVGIcon>
  )
})

CompanyIcon.displayName = 'CompanyIcon'

export default CompanyIcon
