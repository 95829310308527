import { MainLayoutOutletContextValue } from '@components/main-layout'
import { useOutletContext } from 'react-router-dom'
import { NewDriverForm } from '../components/new-driver-form'

export function NewDriver() {
  const { setPageTitle, setPageSubTitle, setBackgroundColor, setMode } =
    useOutletContext<MainLayoutOutletContextValue>()

  setMode('form')
  setPageTitle('Novo Motorista')
  setPageSubTitle('')
  setBackgroundColor('primary')

  return (
    <main className="w-full h-full relative">
      <NewDriverForm />
    </main>
  )
}
