import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { Form } from '~/components/ui/form'
import { TextField } from '@components/ui/text-field'
import { Button } from '~/components/ui/button'
import { useNavigate } from 'react-router-dom'
import { addDriver } from '../../use-cases/add-driver'
import { useSession } from '@/auth/contexts/session'
import { toast } from 'react-toastify'

const requiredString = z
  .string()
  .refine((str) => !!str.length, 'Campo obrigatório')

const formSchema = z.object({
  cpf: requiredString,
  name: requiredString,
  rg: z.string(),
})

export function NewDriverForm() {
  const { session } = useSession()

  const navigate = useNavigate()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      cpf: '',
      name: '',
      rg: '',
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const result = await addDriver({
      ...values,
      company: session.user!.company.id,
    })

    if (result.isFailure) {
      console.error(result.error!.cause.stack)
      toast.error(result.error?.message)
      return
    }

    toast.success('Motorista cadastrado com sucesso.')
    navigate(`/editar-motorista/${result.value.cpf}`)
  }

  return (
    <Form {...form}>
      <form
        className="fixed top-0 -z-10 h-screen w-full flex flex-col bg-background"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="h-full flex flex-row flex-wrap justify-center content-start gap-y-4 gap-x-4 px-8 pb-8 pt-28 border-b-2 rounded-b-3xl overflow-y-auto bg-primary shadow-inner-bottom no-scrollbar">
          <TextField
            className="min-w-52 max-w-80"
            name="cpf"
            label="CPF"
            form={form}
          />
          <TextField
            className="min-w-52 max-w-80"
            name="name"
            label="Nome do motorista"
            form={form}
          />
          <TextField
            className="min-w-52 max-w-80"
            name="rg"
            label="RG"
            form={form}
          />
        </div>
        <div className="bottom-0 w-full flex justify-center px-8 py-5 gap-x-4 bg-background">
          <Button
            className="w-full rounded-sm shadow-light-sm uppercase tracking-widest"
            variant="outline"
            onClick={() => navigate('/motoristas')}
          >
            Cancelar
          </Button>
          <Button
            className="w-full rounded-sm shadow-light-sm uppercase tracking-widest"
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </form>
    </Form>
  )
}
