import { SessionContextValue } from '@/auth/contexts/session'
import { LoaderFunction, LoaderFunctionArgs, redirect } from 'react-router-dom'

export function useNavigationGuards(...guards: LoaderFunction[]) {
  return async (args: LoaderFunctionArgs) => {
    for (let guard of guards) {
      const response = await guard(args)
      if (response !== null) return response
    }
    return null
  }
}

export function useUnauthGuard({ session }: SessionContextValue) {
  return () => {
    console.debug(
      'unauthGuard guarding session: ' + JSON.stringify(session, undefined, 1)
    )
    if (session.user) {
      return redirect('/inicio')
    }
    return null
  }
}

export function useAuthGuard({ session }: SessionContextValue) {
  return () => {
    if (!session.user) return redirect('/')
    return null
  }
}
