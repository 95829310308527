import { DocumentData } from 'firebase/firestore'
import { FirestoreConverter } from '@/common/infra/firebase/firestore/firestore.converter'
import { Driver } from '@/driver/models/driver'

export class DriverConverter extends FirestoreConverter<Driver, string> {
  convertModel(model: Driver): DocumentData {
    return {
      name: model.name,
      rg: model.rg,
    }
  }

  convertDocument(id: string, doc: DocumentData): Driver {
    return new Driver(id, doc.name, doc.rg)
  }
}
