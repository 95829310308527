import '~/truck-loader.scss'

export function TruckLoader() {
  return (
    <>
      <div className="TruckLoader">
        <div className="TruckLoader-cab"></div>
        <div className="TruckLoader-smoke"></div>
      </div>
      <hr />
    </>
  )
}
