import { useEffect, useRef, useState } from 'react'
import { Button } from './button'
import SearchIcon from './icons/search-icon'
import ChevronDownIcon from './icons/chevron-down'
import { cn } from '@lib/utils'

function DataGridItem<T>({
  item,
  renderHeader,
  renderContent,
  renderFooter,
}: {
  item: T
  renderHeader: (item: T) => JSX.Element
  renderContent?: (item: T) => JSX.Element
  renderFooter?: (item: T) => JSX.Element
}) {
  const hasExpanded = useRef(false)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    hasExpanded.current = expanded
  }, [expanded])

  function handleToggle(e: React.UIEvent) {
    e.stopPropagation()
    setExpanded(!expanded)
  }

  return (
    <div
      className={cn(
        'transition-all relative py-3 rounded-2xl bg-card shadow-card-sm overflow-hidden',
        renderContent
          ? expanded
            ? 'gap-y-2 animate-data-grid-item-expand'
            : 'cursor-pointer max-h-14 ' +
              (hasExpanded.current && 'animate-data-grid-item-collapse')
          : ''
      )}
      onClick={() => renderContent && !expanded && setExpanded(true)}
    >
      <div className="w-full flex px-2">
        <button
          className={cn(
            'absolute h-8 bg-transparent',
            renderContent ? '' : 'hidden'
          )}
          onClick={(e) => handleToggle(e)}
        >
          <ChevronDownIcon
            className={cn('transition-all', expanded ? 'rotate-180' : '')}
            color="foreground"
            size="md"
          />
        </button>
        {renderHeader(item)}
      </div>
      <div
        className={cn(
          'transition-all px-5',
          expanded ? '' : 'invisible',
          renderContent ? '' : 'hidden'
        )}
      >
        {renderContent?.(item)}
      </div>
      <div>{renderFooter?.(item)}</div>
    </div>
  )
}

export function DataGrid<T>({
  items,
  searchable = true,
  getKey,
  ...props
}: {
  items: T[]
  getKey: (item: T) => string
  renderHeader: (item: T) => JSX.Element
  renderContent?: (item: T) => JSX.Element
  renderFooter?: (item: T) => JSX.Element
  searchable?: boolean
}) {
  const gridItems = items.map((item) => (
    <DataGridItem key={getKey(item)} item={item} {...props} />
  ))

  return (
    <div className="w-full flex flex-col flex-nowrap gap-y-5">
      <div className={cn('w-full flex justify-end', !searchable && 'hidden')}>
        <Button className="bg-transparent" variant="ghost" size="icon">
          <SearchIcon color="primary" size="md" />
        </Button>
      </div>
      {gridItems}
    </div>
  )
}
