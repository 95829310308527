import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTrigger,
} from '~/@components/ui/sheet'
import { Button } from '~/components/ui/button'
import { TextField } from '~/components/ui/text-field'
import { changePassword } from '../../use-cases/change-password'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { reauth } from '../../use-cases/reauth'
import { Form } from '~/components/ui/form'
import { InvalidCredentialsError } from '../../errors/invalid-credentials.error'
import { TooManyLoginAttemptsError } from '../../errors/too-many-login-attemps.error'

const requiredString = z.string().min(1, 'Campo obrigatório')
const formSchema = z
  .object({
    currentPassword: requiredString,
    newPassword: requiredString,
    passwordConfirm: requiredString,
  })
  .refine(
    ({ newPassword, passwordConfirm }) => newPassword === passwordConfirm,
    {
      message: `Senhas não coincidem`,
      path: ['passwordConfirm'],
    }
  )

export function ChangePasswordSheet() {
  const [open, setOpen] = useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      passwordConfirm: '',
    },
  })

  const onSubmit = async ({
    newPassword,
    currentPassword,
  }: z.infer<typeof formSchema>) => {
    const reauthResult = await reauth(currentPassword)
    if (reauthResult.isFailure) {
      let message
      switch (reauthResult.error!.constructor) {
        case InvalidCredentialsError:
          message = 'Senha errada'
          break
        case TooManyLoginAttemptsError:
          message =
            'Muitas tentativas. Espere um pouco e tente novamente depois.'
          break
        default:
          return
      }
      form.setError('currentPassword', { message })
      return
    }

    const result = await changePassword(newPassword)
    if (result.isFailure) {
      toast.error(result.error!.message)
      return
    }
    toast.success('Senha alterada.')
    setOpen(false)
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button className="w-full min-w-52 max-w-80" variant="secondary">
          Mudar senha
        </Button>
      </SheetTrigger>
      <SheetContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <SheetHeader className="text-primary mb-6">Mudar senha</SheetHeader>
            <SheetDescription>
              Informe a sua nova senha e confirme. Clique em "salvar" para
              concluir.
            </SheetDescription>
            <div className="w-full flex flex-col py-8 gap-y-4">
              <TextField
                form={form}
                type="password"
                name="currentPassword"
                label="Senha atual"
                variant="secondary"
              />
              <TextField
                form={form}
                type="password"
                name="newPassword"
                label="Nova senha"
                variant="secondary"
              />
              <TextField
                form={form}
                type="password"
                name="passwordConfirm"
                label="Confirmar senha"
                variant="secondary"
              />
            </div>
            <SheetFooter>
              <Button type="submit" className="w-full" variant="default">
                Salvar
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  )
}
