import React from 'react'
import SVGIcon from './svg-icon'

const ChevronDownIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>((props, ref) => {
  return (
    <SVGIcon ref={ref} {...props} viewBox="0 0 24 24">
      <path d="M7.41 8.58008L12 13.1701L16.59 8.58008L18 10.0001L12 16.0001L6 10.0001L7.41 8.58008Z" />
    </SVGIcon>
  )
})

ChevronDownIcon.displayName = 'ChevronDownIcon'

export default ChevronDownIcon
