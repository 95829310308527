import Logo from '@components/ui/logo'
import { LoginForm } from '../components/login-form'

export function Login() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-9">
      <div className="relative flex place-items-center mb-10">
        <Logo />
      </div>
      <LoginForm />
    </main>
  )
}
