import { useSidebarContext } from './sidebar'
import { Button } from './ui/button'
import { HamburgIcon } from './ui/icons/hamburg-icon'
export function SidebarToggle() {
  const { toggle } = useSidebarContext()

  return (
    <Button variant="ghost" size="icon" onClick={() => toggle()}>
      <HamburgIcon />
    </Button>
  )
}
