import { Login } from '@/auth/views/pages/login.page'
import { Signup } from '@/onboarding/views/pages/signup.page'
import { SignupLayout } from '@/onboarding/views/layouts/signup.layout'
import { createBrowserRouter, redirect } from 'react-router-dom'
import { SessionContextValue } from '@/auth/contexts/session'
import {
  useAuthGuard,
  useNavigationGuards,
  useUnauthGuard,
} from '@lib/navigation-guards'
import { Home } from '@components/home'
import { MainLayout } from '@components/main-layout'
import { Vehicles } from '@/vehicle/views/pages/vehicles.page'
import { NewVehicle } from '@/vehicle/views/pages/new-vehicle.page'
import { EditVehicle } from '@/vehicle/views/pages/edit-vehicle.page'
import { getVehiclesRepository } from '@/vehicle/infra/firebase/repositories/vehicle.repository'
import { Drivers } from '@/driver/views/pages/drivers.page'
import { EditDriver } from '@/driver/views/pages/edit-driver.page'
import { getDriversRepository } from '@/driver/infra/firebase/repositories/driver.repository'
import { NewDriver } from '@/driver/views/pages/new-driver.page'
import { Users } from '@/user/views/pages/users.page'
import { getUserRepository } from '@/user/infra/firebase/repositories/user.repository'
import { NewUser } from '@/user/views/pages/new-user.page'
import { EditUser } from '@/user/views/pages/edit-user.page'
import { MyAccount } from '@/user/views/pages/my-account.page'
import { CompanyType } from '@/company/models/company'

export function useRouter(sessionContext: SessionContextValue) {
  const authGuard = useAuthGuard(sessionContext)
  const unauthGuard = useUnauthGuard(sessionContext)
  const transporterGuard = () => {
    if (sessionContext.session.user?.company.type !== CompanyType.TRANSPORTER) {
      return redirect('/inicio')
    }
    return null
  }

  return createBrowserRouter([
    {
      path: '',
      element: <Login />,
      loader: unauthGuard,
    },
    {
      path: '/cadastrar-se',
      element: <SignupLayout />,
      children: [
        {
          path: '',
          element: <Signup />,
          loader: unauthGuard,
        },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'inicio',
          element: <Home />,
          loader: authGuard,
        },
        {
          path: 'veiculos',
          element: <Vehicles />,
          loader: useNavigationGuards(authGuard, transporterGuard),
        },
        {
          path: 'novo-veiculo',
          element: <NewVehicle />,
          loader: useNavigationGuards(authGuard, transporterGuard),
        },
        {
          path: 'editar-veiculo/:plate',
          element: <EditVehicle />,
          loader: useNavigationGuards(
            authGuard,
            transporterGuard,
            async ({ params }) => {
              const vehiclesRepository = getVehiclesRepository()
              const vehicleOrNull = await vehiclesRepository.get(
                sessionContext.session.user!.company.id,
                params.plate!
              )
              if (vehicleOrNull === null) return redirect('/veiculos')
              return { vehicle: vehicleOrNull }
            }
          ),
        },
        {
          path: 'motoristas',
          element: <Drivers />,
          loader: useNavigationGuards(authGuard, transporterGuard),
        },
        {
          path: 'novo-motorista',
          element: <NewDriver />,
          loader: useNavigationGuards(authGuard, transporterGuard),
        },
        {
          path: 'editar-motorista/:cpf',
          element: <EditDriver />,
          loader: useNavigationGuards(
            authGuard,
            transporterGuard,
            async ({ params }) => {
              const driversRepository = getDriversRepository()
              const driverOrNull = await driversRepository.get(
                sessionContext.session.user!.company.id,
                params.cpf!
              )
              console.debug(driverOrNull)
              if (driverOrNull === null) return redirect('/motoristas')
              return { driver: driverOrNull }
            }
          ),
        },
        {
          path: 'usuarios',
          element: <Users />,
          loader: useNavigationGuards(authGuard, async () => {
            const userRepository = getUserRepository()
            const users = await userRepository.getCompanyUsers(
              sessionContext.session.user!.company.id
            )
            return { users }
          }),
        },
        {
          path: 'novo-usuario',
          element: <NewUser />,
          loader: authGuard,
        },
        {
          path: 'editar-usuario/:id',
          element: <EditUser />,
          loader: useNavigationGuards(authGuard, async ({ params: { id } }) => {
            if (!id) return

            if (id === sessionContext.session.user?.id) {
              return redirect('/minha-conta')
            }

            const userRepository = getUserRepository()
            const user = await userRepository.get(id)
            return { user }
          }),
        },
        {
          path: 'minha-conta',
          element: <MyAccount />,
          loader: useNavigationGuards(authGuard, async () => {
            const userRepository = getUserRepository()
            const user = await userRepository.get(
              sessionContext.session.user!.id
            )
            return { user }
          }),
        },
      ],
    },
  ])
}
