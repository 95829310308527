import React from 'react'
import SVGIcon from './svg-icon'

const HomeIcon = React.forwardRef<
  React.ElementRef<typeof SVGIcon>,
  React.ComponentPropsWithoutRef<typeof SVGIcon>
>(({ className, ...props }, ref) => {
  return (
    <SVGIcon ref={ref} {...props} viewBox="0 0 26 26">
      <path d="M3.25 23.0286H8.125V15.6C8.125 15.179 8.281 14.8264 8.593 14.5422C8.905 14.2579 9.29066 14.1153 9.75 14.1143H16.25C16.7104 14.1143 17.0966 14.2569 17.4086 14.5422C17.7206 14.8274 17.8761 15.18 17.875 15.6V23.0286H22.75V9.65714L13 2.97143L3.25 9.65714V23.0286ZM0 23.0286V9.65714C0 9.18666 0.115375 8.74095 0.346125 8.32C0.576875 7.89905 0.894833 7.55238 1.3 7.28L11.05 0.594286C11.6187 0.198095 12.2687 0 13 0C13.7312 0 14.3812 0.198095 14.95 0.594286L24.7 7.28C25.1062 7.55238 25.4247 7.89905 25.6555 8.32C25.8862 8.74095 26.0011 9.18666 26 9.65714V23.0286C26 23.8457 25.6815 24.5455 25.0445 25.1279C24.4075 25.7103 23.6427 26.001 22.75 26H16.25C15.7896 26 15.4039 25.8574 15.093 25.5721C14.7821 25.2869 14.6261 24.9342 14.625 24.5143V17.0857H11.375V24.5143C11.375 24.9352 11.219 25.2883 10.907 25.5736C10.595 25.8589 10.2093 26.001 9.75 26H3.25C2.35625 26 1.59142 25.7093 0.955499 25.1279C0.319583 24.5465 0.00108333 23.8467 0 23.0286Z" />
    </SVGIcon>
  )
})

HomeIcon.displayName = 'HomeIcon'

export default HomeIcon
