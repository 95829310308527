import { DocumentData } from 'firebase/firestore'
import { FirestoreConverter } from '@/common/infra/firebase/firestore/firestore.converter'
import { Vehicle } from '@/vehicle/models/vehicle'

export class VehicleConverter extends FirestoreConverter<Vehicle, string> {
  convertModel(model: Vehicle): DocumentData {
    return {
      name: model.name,
      renavam: model.renavam,
      transportType: model.transportType,
      vehicleType: model.vehicleType,
      wheelset: model.wheelset,
      body: model.body,
      tare: model.tare,
      massCapacity: model.massCapacity,
      volumeCapacity: model.volumeCapacity,
      state: model.state,
      brand: model.brand,
      model: model.model,
    }
  }

  convertDocument(id: string, doc: DocumentData): Vehicle {
    return new Vehicle(
      id,
      doc.name,
      doc.renavam,
      doc.transportType,
      doc.vehicleType,
      doc.body,
      doc.wheelset,
      doc.tare,
      doc.massCapacity,
      doc.volumeCapacity,
      doc.state,
      doc.brand,
      doc.model
    )
  }
}
