import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { Button } from '~/components/ui/button'
import { TextField } from '~/components/ui/text-field'
import { Form } from '~/components/ui/form'
import { useBranchMenuContext } from '~/components/branch-menu'
import { useSession } from '@/auth/contexts/session'
import { toast } from 'react-toastify'
import { FileField } from '~/components/ui/file-field'

const requiredString = z.string().min(1, 'Campo obrigatório')
const formSchema = z.object({
  name: requiredString,
  cnpj: requiredString,
  digitalCertificate: z
    .optional(z.instanceof(File))
    .refine(
      (val) => !val || val.type === 'application/x-pkcs12',
      'O tipo do arquivo do certificado deve ser PKCS-12 (".pfx")'
    ),
  digitalCertificatePassword: z.string().optional(),
})

export function NewBranchForm() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      cnpj: '',
      name: '',
    },
  })

  const { closeForm } = useBranchMenuContext()

  const { addBranch } = useSession()
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await addBranch(values)

    if (result.isFailure) {
      toast.error(result.error!.message)
      return
    }

    toast.success('Filial cadastrada.')
    closeForm()
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="px-4">
        <div className="text-primary-foreground">Cadastrar filial</div>
        <div className="w-full flex flex-col py-2 gap-y-2">
          <TextField form={form} name="name" label="Nome" />
          <TextField form={form} name="cnpj" label="CNPJ" />
          <FileField
            form={form}
            name="digitalCertificate"
            label="Certificado digital"
          />
          <TextField
            form={form}
            type="password"
            name="digitalCertificatePassword"
            label="Senha do certificado"
          />
        </div>
        <Button
          type="submit"
          className="w-full uppercase tracking-wide my-6"
          variant="secondary"
        >
          Cadastrar
        </Button>
        <Button
          type="button"
          className="w-full uppercase tracking-wide"
          variant="outline"
          onClick={() => closeForm()}
        >
          Cancelar
        </Button>
      </form>
    </Form>
  )
}
