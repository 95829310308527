import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from './form'
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select'
import { cn, composeEventHandlers } from '~/lib/utils'
import React from 'react'

export type SelectOptions =
  | { value: string; label: string }[]
  | { [value: string]: string }

function buildSelectItems(options: SelectOptions) {
  if (Array.isArray(options)) {
    return options.map(({ value, label }) => (
      <SelectItem value={value}>{label}</SelectItem>
    ))
  } else {
    return Object.keys(options).map((value) => (
      <SelectItem value={value}>{options[value]}</SelectItem>
    ))
  }
}

export function SelectField<FormSchema extends FieldValues>({
  className,
  form,
  name,
  placeholder = '-------',
  label,
  options,
  onValueChange = () => {},
}: {
  className?: string
  form: UseFormReturn<FormSchema, any, undefined>
  name: FieldPath<FormSchema>
  placeholder?: string
  label: string
  type?: React.HTMLInputTypeAttribute
  options: SelectOptions
  onValueChange?: (val: string) => void
}) {
  const selectItems = buildSelectItems(options)
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn('w-full', className)}>
          <FormLabel className="text-sm text-primary-foreground font-light">
            {label}
          </FormLabel>
          <Select
            onValueChange={composeEventHandlers(onValueChange, field.onChange)}
            defaultValue={field.value}
          >
            <FormControl>
              <SelectTrigger className="border-primary text-foreground">
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>{selectItems}</SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
