import {
  Firestore,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { MaybeWithTimestamps, Model, Stringfiable } from '@/common/model'
import { UniqueId } from '@/common/unique-id'
import {
  FirestoreConverter,
  MaybeTimestampedDocument,
} from './firestore.converter'
import { firebase } from '~/lib/firebase'

export abstract class SubcollectionRepository<
  M extends Model<ID>,
  ID extends Stringfiable = UniqueId
> {
  protected abstract subcollection: string

  protected abstract readonly collection: string
  protected abstract readonly converter: FirestoreConverter<M, ID>
  protected readonly firestore: Firestore

  constructor() {
    const { db } = firebase()
    this.firestore = db
  }

  public async add(colId: Stringfiable, model: M): Promise<void> {
    await setDoc(
      this.getRef(colId.toString(), model.id.toString()),
      this.converter.toFirestore(model)
    )
  }

  public async getAll(colId: string): Promise<MaybeWithTimestamps<M, ID>[]> {
    const querySnapshot = await getDocs(this.getCollection(colId))
    return querySnapshot.docs.map((doc) =>
      this.converter.fromFirestore(
        doc.id,
        doc.data() as MaybeTimestampedDocument
      )
    )
  }

  async get(
    colId: string,
    id: string
  ): Promise<MaybeWithTimestamps<M, ID> | null> {
    const ref = this.getRef(colId, id)
    const docSnap = await getDoc(ref)
    return docSnap.exists()
      ? this.converter.fromFirestore(
          docSnap.id,
          docSnap.data() as MaybeTimestampedDocument
        )
      : null
  }

  async update(colId: string, model: M): Promise<void> {
    const ref = this.getRef(colId, model.id.toString())
    await updateDoc(ref, this.converter.toFirestore(model))
  }

  async delete(colId: string, id: string): Promise<void> {
    const ref = this.getRef(colId, id)
    await deleteDoc(ref)
  }

  protected getRef(colId: string, id: string) {
    return doc(this.firestore, this.collection, colId, this.subcollection, id)
  }

  protected getCollection(colId: string) {
    return collection(
      this.firestore,
      this.collection,
      colId,
      this.subcollection
    )
  }
}
