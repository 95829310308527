import { zodResolver } from '@hookform/resolvers/zod'
import { Control, ControllerRenderProps, useForm } from 'react-hook-form'
import * as z from 'zod'
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../components/ui/form'
import { Button } from '../../../../components/ui/button'
import {
  RadioButtonGroup,
  RadioButtonGroupItem,
} from '../../../../components/ui/radio-button-group'
import { cn, composeEventHandlers } from '@lib/utils'
import { CompanyType } from '../../../company/models/company'
import { EmailAlreadyRegisteredError } from '../../../auth/errors/email-already-registered.error'
import { InvalidPasswordError } from '../../../auth/errors/invalid-password.error'
import { useSession } from '../../../auth/contexts/session'
import { UnexpectedError } from '../../../common/errors/unexpected.error'
import { TextField } from '../../../../components/ui/text-field'
import { Link, useNavigate } from 'react-router-dom'
import TruckIcon from '@components/ui/icons/truck-icon'
import CompanyIcon from '@components/ui/icons/company-icon'
import { FileField } from '~/components/ui/file-field'

const requiredString = z.string().min(1, 'Campo obrigatório')
const formSchema = z
  .object({
    companyType: z.enum(['transportador', 'fornecedor'], {
      required_error: 'Campo obrigatório',
    }),
    name: requiredString,
    email: requiredString.email('Email inválido'),
    phone: requiredString,
    cnpj: requiredString,
    digitalCertificate: z
      .optional(z.instanceof(File))
      .refine(
        (file) => !file || file.type === 'application/x-pkcs12',
        'O tipo do arquivo do certificado deve ser PKCS-12 (".pfx")'
      ),
    digitalCertificatePassword: z.string(),
    companyName: requiredString,
    password: requiredString.min(6, 'A senha deve ter no mínimo 6 caracteres'),
    passwordConfirm: requiredString,
  })
  .superRefine((form, ctx) => {
    if (form.password !== form.passwordConfirm) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Senhas não coincidem.`,
        path: ['passwordConfirm'],
      })
    }
  })

function CompanyTypeRadio({
  children,
  value,
  field,
}: {
  children: React.ReactNode
  value: string
  field: ControllerRenderProps<z.infer<typeof formSchema>, 'companyType'>
}) {
  const selected = value === field.value
  return (
    <FormItem className="w-full">
      <FormControl>
        <RadioButtonGroupItem className="transition-all h-full" value={value}>
          <div
            className={cn(
              'w-full',
              'flex',
              'flex-col',
              'justify-center',
              'items-center',
              'sm:gap-4',
              'gap-2',
              'sm:py-6',
              'py-4',
              'rounded-lg',
              'border',
              'bg-card',
              'text-card-foreground',
              'bg-primary',
              'border-none',
              'text-primary-foreground',
              'min[320px]-text-xs',
              'sm:text-sm',
              'text-[10px]',
              selected ? 'shadow-inner-light-sm' : 'shadow-light-sm'
            )}
          >
            {children}
          </div>
        </RadioButtonGroupItem>
      </FormControl>
    </FormItem>
  )
}

function CompanyTypeRadioGroup({
  control,
  onValueChange = () => {},
}: {
  control: Control<z.infer<typeof formSchema>>
  onValueChange?: (value: string) => void
}) {
  return (
    <FormField
      control={control}
      name="companyType"
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel className="text-sm text-primary-foreground font-light">
            Tipo de conta
          </FormLabel>
          <FormControl className="w-full flex gap-8">
            <RadioButtonGroup
              onValueChange={composeEventHandlers(
                field.onChange,
                onValueChange
              )}
              defaultValue={field.value}
            >
              <CompanyTypeRadio value="transportador" field={field}>
                <div className="flex justify-center items-center sm:w-8 w-6 sm:h-8 h-6">
                  <TruckIcon color="primary-foreground" />
                </div>
                <span className="min-[321px]:uppercase tracking-wider">
                  Transportador
                </span>
              </CompanyTypeRadio>
              <CompanyTypeRadio value="fornecedor" field={field}>
                <div className="flex justify-center items-center sm:w-8 w-6 sm:h-8 h-6">
                  <CompanyIcon color="primary-foreground" />
                </div>
                <span className="min-[321px]:uppercase tracking-wider">
                  Fornecedor
                </span>
              </CompanyTypeRadio>
            </RadioButtonGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function SignupForm() {
  const navigate = useNavigate()
  const { signup } = useSession()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      companyType: undefined,
      name: '',
      email: '',
      phone: '',
      cnpj: '',
      companyName: '',
      password: '',
      passwordConfirm: '',
      digitalCertificatePassword: '',
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const result = await signup({
      ...values,
      companyType: values.companyType as CompanyType,
    })

    if (result.isFailure) {
      let message = 'Erro inesperado ao realizar cadastro.'
      switch (result.error?.constructor) {
        case EmailAlreadyRegisteredError:
          message = 'E-mail já cadastrado.'
          break
        case InvalidPasswordError:
          message = 'Senha inválida.'
          break
        case UnexpectedError:
          console.error((result.error as UnexpectedError).cause)
          break
      }
      toast.error(message)
      return
    }

    navigate('/inicio')
  }

  const [companyType, setCompanyType] = useState('')

  return (
    <div className="max-w-sm w-full">
      <Form {...form}>
        <form
          className="flex flex-col w-full gap-y-4"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <CompanyTypeRadioGroup
            control={form.control}
            onValueChange={setCompanyType}
          />
          <TextField name="name" label="Nome" form={form} />
          <TextField name="email" type="email" label="Email" form={form} />
          <TextField name="phone" label="Telefone" form={form} />
          <TextField name="companyName" label="Nome da empresa" form={form} />
          <TextField name="cnpj" label="CNPJ" form={form} />
          {companyType === 'transportador' && (
            <>
              <FileField
                name="digitalCertificate"
                label="Certificado digital"
                form={form}
              />
              <TextField
                name="digitalCertificatePassword"
                type="password"
                label="Senha do certificado digital"
                form={form}
              />
            </>
          )}
          <TextField
            name="password"
            type="password"
            label="Senha"
            form={form}
          />
          <TextField
            name="passwordConfirm"
            type="password"
            label="Confirmar senha"
            form={form}
          />
          <Button
            type="submit"
            className="font-medium text-sm mt-14 py-6 w-full border border-primary"
            variant="secondary"
            size="lg"
          >
            Cadastrar-se
          </Button>
        </form>
      </Form>
      <div className="flex flex-col w-full">
        <Link to="/">
          <Button
            className="font-medium text-sm mt-8 py-6 w-full border border-primary"
            variant="ghost"
          >
            Voltar
          </Button>
        </Link>
      </div>
      <ToastContainer />
    </div>
  )
}
