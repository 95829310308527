import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { MainLayoutOutletContextValue } from '~/components/main-layout'
import { MyAccountForm } from '../components/my-account.form'

export function MyAccount() {
  const { setLayoutOptions } = useOutletContext<MainLayoutOutletContextValue>()

  useEffect(() => {
    setLayoutOptions('form', 'minha conta', '', 'primary')
  })

  return (
    <main className="w-full h-full relative">
      <MyAccountForm />
    </main>
  )
}
