import { Result } from './result'

export function ensureSucceed<T>(result: Result<T>): T {
  if (result.isFailure) throw result.error
  return result.value
}

export function ensureAllSucceed(...results: Result<any>[]): any[] {
  return results.map((r) => ensureSucceed(r))
}

export async function ensureWillSucceed<T>(
  willResult: Promise<Result<T>> | Result<T>
): Promise<T> {
  const result = await willResult
  return ensureSucceed(result)
}

export async function ensureAllWillSucceed(
  ...willResults: (Promise<Result<any>> | Result<any>)[]
): Promise<any[]> {
  return Promise.all(willResults).then((results) =>
    ensureAllSucceed(...results)
  )
}
